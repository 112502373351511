import { useTranslation } from "react-i18next";
import source from "@/utils/soucre";
import { Tooltip } from "antd";
export default function Item(props: any) {
  const { item } = props;
  const tt = useTranslation().t;
  return (
    <div
      className="text-[#fff] w-[415px] h-[236px] text-left p-[32px] flex items-end m-[15px]"
      style={{
        backgroundImage: `url(${source.privilegeListBg})`,
        backgroundSize: "100% 100%",
      }}
    >
      <div>
        <div className="h20">{tt("privilege.listTitle" + item)}</div>
        <Tooltip placement="top" title={tt("privilege.listContent"+item)}>
          <p className="line-clamp-2 p14 text-[rgba(255,255,255,0.5)] mt-[8px]">
            {tt("privilege.listContent" + item)}
          </p>
        </Tooltip>
      </div>
    </div>
  );
}
