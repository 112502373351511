import { useLang } from "@/hooks";
import { Link } from "react-router-dom";
export default function Item({ item }: any) {
  const lang = useLang();
  return (
    <Link to={"/cusPage/" + item.id}>
      <div className="h-[100px] rounded-[16px]  leading-[90px] bg-[rgba(0,0,0,0.6)] border-[1px] flex justify-start items-center border-[rgba(255,255,255,0.3)]">
        <div className="ml-[56px] mr-[10px] text-[24px] iconfont icon-a-DownloadSquare text-[rgba(255,255,255,0.5)]"></div>
        <div className="w-[400px] p20 font-pls_m line-clamp-1">
          {lang === "zh" ? item.title : item.enTitle}
        </div>
      </div>
    </Link>
  );
}
