const data = {
  nav0: "交易",

  nav1: "关于AIXG",
  nav10: "关于我们",
  nav11: "安全措施",
  nav12: "招聘职位",
  nav13: "媒体报道",
  nav14: "联盟计划",

  nav2: "产品",
  nav20: "合约交易",
  nav21: "现货交易",
  nav22: "杠杆交易",
  nav23: "期权交易",
  nav24: "Ai算力",

  nav3: "服务",
  nav30: "Vip服务",
  nav31: "上币申请",
  nav32: "费率介绍",
  nav33: "Bank计划",
  nav34: "重要公告",

  nav4: "合规",
  nav40: "法律声明",
  nav41: "用户协议",
  nav42: "隐私条款",
  nav43: "风险提示",
  nav44: "环球认证",

  nav5: "AIX学院",
  nav50: "成长中心",
  nav51: "帮助中心",
  nav52: "常见问题",
  nav53: "了解加密货币",
  downApp: "下载APP",
  footer:
    "使用Enigma解锁您的编码潜力",
};
export default data;
