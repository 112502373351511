const data = {
  one: {
    text1: "Unlock Your Coding Potential with Enigma Code-ai",
    btn1: "Get Started",
    btn2: "Sign up",
    text2: "Or Use",
  },
  two: {
    text1: "Choose a character for your",
    text2: "AI Chat",
    name: "Bob",
  },
  three: {
    text1: "Market",
    text2: "Coding Potential with Enigma Code-ai",
    th01: "Name",
    th02: "Price",
    th03: "Range",
    th04: "Trend",
    th05: "Trading",
    top: "Top Movers",
    seeAll: "See All",
  },
  four: {
    text1: "Why us",
    text2:
      "Regulated, AI-Powered, and Secure – Expanding Your Horizons with Endless Opportunities",
    listTitle1: "Licensed Operation",
    listTitle2: "Ai Incubation",
    listTitle3: "Safety",
    listContent1:
      "AIXG Has Been Approved for a U.S. Treasury Financial Regulation MSB License",
    listContent2:
      "AiXG cooperates with major funds to incubate innovative AI projects, promote technology development, and create more quality application scenarios and tools.",
    listContent3:
      "Protecting customer assets and privacy has always been our top priority. The cutting-edge security technology team adopts the highest international financial security level to fully safeguard the security of user assets.",
    btn: "Learn more",
  },
  five: {
    text1: "Roadmap 2025 Annual Outlook",
    text2:
      "AIXG business covers crypto asset trading, financial derivatives, industry research, AI investment incubation, content services and other fields. As the world's leading Web 3.0 portal, it is committed to providing comprehensive, secure and trustworthy services and support for cryptocurrency investors around the world.",
    listTitle1: "Short-term goals",
    listContent1:
      "2025 Product goes live in public beta for 10,000 people and achieves 100,000+ registered users.",
    listTitle2: "Medium-term objectives",
    listContent2: "Reach 500,000+ users in 2 to 3 months.",
    listTitle3: "Growth targets",
    listContent3:
      "Within 6 months, a total platform turnover of 1,000,000,000 USD will be realized.",
    label3: "(within six months)",
    listTitle4: "Long-term objectives",
    listContent4:
      "Within one year , AiXG will be ranked among the top 20 leading exchanges in the world, and will establish AiXG's position as an industry leader through continuous technological innovation, security, compliance, and excellent user services.",
    label: "(within 1 year)",
  },
  six: {
    text1: "Open trading anytime, anywhere",
    text2: "Full support for iOS, Android, API",
    listTitle1: "Short-term goals",
    listContent1:
      "2025 Product goes live in public beta for 10,000 people and achieves 100,000+ registered users.",
    listTitle2: "Medium-term objectives",
    listContent2: "Reach 500,000+ users in 2 to 3 months.",
    listTitle3: "Growth targets",
    listContent3:
      "Within 6 months, a total platform turnover of 1,000,000,000 USD will be realized.",
    label3: "(within six months)",
    listTitle4: "Long-term objectives",
    listContent4:
      "Within one year , AiXG will be ranked among the top 20 leading exchanges in the world, and will establish AiXG's position as an industry leader through continuous technological innovation, security, compliance, and excellent user services.",
    label: "(within 1 year)",
    btn: "Download",
  },
  seven: {
    text1: "Partners",
    text2: "Coding Potential with Enigma Code-ai",
    btn1: "Sign Up Now",
    btn2: "Login",
  },
};

export default data;
