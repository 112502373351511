import { useLang } from "@/hooks/index";
export default function NewItem({ item }: any) {
  const lang = useLang();
  return (
    <div className="text-left flex py-[30px] border-b-[1px] border-[#2C2C30]">
      <div className="xl:w-[240px] w-[30%] bg-blue-50 xl:mr-[38px] mr-[10px]">
        <img src={item.imgUrl} alt="" />
      </div>
      <div className="text-[#fff] w-[70%] flex-1">
        <div className="h20">{lang === "zh" ? item.title : item.enTitle}</div>
        <div className="line-clamp-3  mt-[10px] ">
          {lang === "zh" ? item.desc : item.enDesc}
        </div>
        <p className="p16 text-[rgba(255,255,255,0.5)] mt-[10px]">
          AIXG . {item.createDate}
        </p>
      </div>
    </div>
  );
}
