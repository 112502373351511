import soure from "@/utils/soucre";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import "./index.scss";
import Button from "@/components/button";
import PageBg from "@/components/pageBg";
import { getHireList, applyHire } from "@/api";
import { useLang } from "@/hooks";
import { Link } from "react-router-dom";
import { message } from "antd";

export default function Hire() {
  const tt = useTranslation().t;
  const [list, setList] = useState<any[]>([]);
  const [email, setEmail] = useState<string>("");
  const lang = useLang();
  const [disabled, setDisabled] = useState(false);

  const dealList = (list: any[]) => {
    let temList: any[] = [];
    list.forEach((item) => {
      let index = temList.findIndex(
        (item2: any) => item2.classId === item.classId
      );
      if (index !== -1) {
        temList[index].children.push(item);
      } else {
        temList.push({
          classId: item.classId,
          className: item.className,
          enClassName: item.enClassName,
          children: [item],
        });
      }
    });
    setList(temList);
  };

  const getHireListFun = () => {
    getHireList({}).then((res) => {
      dealList(res);
    });
  };

  useEffect(() => {
    getHireListFun();
  }, []);

  const applyFun = () => {
    if (!email.trim()) {
      message.error(tt("hire.errorTip"));
      return;
    }
    applyHire({ email }).then((res) => {
      message.success(tt("hire.succussTip"));
      setDisabled(true);
    });
  };
  return (
    <div
      className="w-full  bg-no-repeat pb-[100px]"
      style={{
        backgroundImage: `url(${soure.newBg})`,
        backgroundSize: "100%",
        backgroundColor: "#050506",
      }}
    >
      <div className="xl:w-[85vw] w-[90vw] pt-[196px] mx-auto">
        {/* 标题 */}
        <div className="mb-[36px]">
          <div className="h48 text-center text-[#fff]">{tt("hire.text1")}</div>
          <div className="text-center">
            <div className="p20 textColor_white mt-[16px]">
              {tt("hire.text2")}
            </div>
          </div>
        </div>
        {/* 内容 */}
        <PageBg>
          <div className="h32 pt-[80px] w-[50vw] mx-auto">
            {tt("hire.text3")}
          </div>
          <div className="p16 pt-[68px]  mx-auto"> {tt("hire.text4")}</div>
          {/* 搜索框 */}
          <div
            className="w-[430px] h-[106px] mx-auto mt-[36px] flex justify-between"
            style={{
              backgroundImage: `url(${soure.hireInputBg})`,
            }}
          >
            <div>
              <input
                type="text"
                className="h-[60px] w-[220px] bg-transparent ml-[20px] outline-none"
                placeholder={tt("hire.text5")}
                value={email}
                onInput={(e: any) => setEmail(e.target.value)}
              />
            </div>
            <div className="mx-[10px] h-[60px] leading-[60px]">
              <Button
                style={{
                  padding: "8px 16px",
                }}
                onClick={applyFun}
                disabled={disabled}
              >
                {tt("hire.text6")}
                <span className="iconfont icon-xiangshangjiantou-copy text-[12px] ml-[3px]"></span>
              </Button>
            </div>
          </div>
          {/* 分割线 */}
          <div className="w-[100%] mx-auto">
            <img src={soure.splitLine} className="w-[100%]" alt="" />
          </div>
          <div className="text-left">
            <div className="h20 mt-[36px]">{tt("hire.text7")}</div>
            <div className="p16 mt-[10px]">{tt("hire.text8")}</div>
          </div>
          <div className="xl:grid xl:grid-cols-2 gap-12 mb-[100px]">
            {list.map((item) => {
              return (
                <div key={item.classId}>
                  <div className="h20 text-left mt-[48px]">
                    {lang === "zh" ? item.className : item.enClassName}
                  </div>
                  {item.children.map((item2: any) => {
                    return (
                      <Link to={"/hireDetail/" + item2.id} key={item2.id}>
                        <div className="text-left mt-[20px]" key={item2.id}>
                          <p className="h16 line-clamp-1">
                            {lang === "zh" ? item2.title : item2.enTitle}
                          </p>
                          <p className="p16 text-[rgba(255,255,255,0.5)] line-clamp-1">
                            {lang === "zh" ? item2.desc : item2.enDesc}
                          </p>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </PageBg>
      </div>
    </div>
  );
}
