import soucre from "@/utils/soucre";

export default function TopMarket(props: any) {
  let {
    isred = false,
    icon,
    title,
    type,
    number,
    price,
    isDown = false,
  } = props;
  return (
    <div>
      <div className="flex justify-between text-[#fff] py-[15px] relative">
        <div className="flex items-left">
          <div className="h-[40px] w-[40px] mr-[12px]">{icon}</div>
          <div>
            <div className="p16 font-pls_m">{title}</div>
            <div className="p14 weight-r text-[#CFD3DC]">{type}</div>
          </div>
        </div>
        <div>
          <p className="h14">{price}</p>
          <p
            className={[
              "p12 text-right",
              isred ? "text-[#DD3131]" : "text-[#00CD52]",
            ].join(" ")}
          >
            {number}
          </p>
        </div>
      </div>
      {!isDown && (
        <img src={soucre.home.line01} className="h-[1px] w-full" alt=""></img>
      )}
    </div>
  );
}
