import source from "@/utils/soucre";
import "./index.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as Icon1 } from "@/assets/home/five_icon01.svg";
import { ReactComponent as Icon2 } from "@/assets/home/five_icon02.svg";
import { ReactComponent as Icon3 } from "@/assets/home/five_icon03.svg";
import { ReactComponent as Icon4 } from "@/assets/home/five_icon04.svg";
import { useIsMoile } from "@/hooks";
import { sreenXL } from "@/utils/const";
import Button from "@/components/button";

export default function BlockSix() {
  const tt = useTranslation().t;
  const isMoile = useIsMoile(sreenXL);
  return (
    <div
      className="relative -mt-[1px]"
      style={{
        backgroundImage: `url(${source.home.homeB06})`,
        backgroundSize: "100% 100%",
      }}
    >
      <div className="wow animate_opacity" data-wow-duration="2s">
        {/* Roadmap 2025 Annual Outlook */}
        <div className="h48 text-[#fff] text-center  weight-b pt-[100px] mb-[16px]">
          {tt("home.six.text1")}
        </div>
        {/* Regulated, AI-Powered, and Secure – Expanding Your Horizons with Endless Opportunities */}
        <div className="text-center w-[80vw] mx-auto">
          <div className="p20 textColor_white ">{tt("home.six.text2")}</div>
        </div>
        <div className="mt-[16px] text-center">
          <Button type={2} style={{ fontWeight: "bold" }}>
            <span className="iconfont icon-download text-[16px]"></span>{" "}
            {tt("home.six.btn")}
          </Button>
        </div>
        <div className="xl:w-auto w-[100vw] mx-auto pb-[200px]">
          <div className="flex justify-center xl:items-end items-center">
            <div className="relative">
              <img src={source.home.sixImg01} alt="" />
              <div className="absolute xl:-bottom-[30%] -bottom-[60%] left-[10%] xl:-translate-x-[50%] xl:w-auto w-[30vw]">
                <img src={source.home.sixImg02} alt="" />
              </div>
              <div className="absolute xl:-bottom-[30%] xl:right-0 xl:translate-x-[50%] z-10 xl:w-auto w-[30vw] -bottom-[60%] -right-[25%] ">
                <img src={source.home.sixImg04} alt="" />
              </div>
            </div>
            <div className="ml-[16px] relative">
              <img src={source.home.sixImg03} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
