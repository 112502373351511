import { useLang } from "@/hooks/index";
import { Link } from "react-router-dom";

export default function Item({ item }: any) {
  const lang = useLang();
  return (
    <Link to={"/cusPage/" + item.id}>
      <div className=" text-[#fff] text-left m-[18px]">
        <div className=" h-[200px] overflow-hidden bg-[#fff] rounded-[8px]">
          <img
            src={item.imgUrl}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div className="h20 line-clamp-1 mt-[10px]">
          {lang === "zh" ? item.title : item.enTitle}
        </div>
        <div className="p16 line-clamp-1">
          {lang === "zh" ? item.desc : item.enDesc}
        </div>
        <div className="p16 text-[rgba(255,255,255,0.5)]">
          <span className="mr-[5px]">WEEX</span>
          <span>{item.createDate}</span>{" "}
        </div>
      </div>
    </Link>
  );
}
