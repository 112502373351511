import source from "@/utils/soucre";
import "./index.css";
import { useTranslation } from "react-i18next";
export default function BlockTwo() {
  const tt = useTranslation().t;

  return (
    <div
      className="bg-cover"
      style={{
        backgroundImage: `url(${source.home.homeB02})`,
        backgroundSize: "100% 100%",
      }}
    >
      <div className="xl:pt-[88px] pt-[46px]">
        <div className="h48 font-pls_m text-center font-pls_b">
          <div className="wow animate__fadeIn" data-wow-duration="2s">
            <div className="textColor_white">{tt("home.two.text1")}</div>
          </div>
          <div className="wow animate__fadeIn" data-wow-duration="2s">
            <div className="textColor_blue">{tt("home.two.text2")}</div>
          </div>
        </div>
        <div className="relative max-w-[1200px] mx-auto mt-[80px] pb-[130px]">
          <div className="xl:flex xl:justify-center xl:items-center gap-[0px]">
            <div
              className="two-img2 relative wow animate__slideInUp -right-[22px]"
              data-wow-duration="1s"
            >
              <img
                src={source.home.homeImg01}
                className="w-full wow animate_opacity opacity-10"
                data-wow-duration="1s"
                data-wow-delay="1.1s"
                alt=""
              />
              {/* <div className="xl:h36 text-[16px] absolute bottom-8 left-[50%] -translate-x-[50%] text-[#fff]">
                {tt("home.two.name")}
              </div> */}
            </div>
            <div
              className="two-img1 relative wow animate__slideInUp"
              data-wow-duration="1s"
            >
              <img
                src={source.home.homeImg02}
                className="w-full wow animate_opacity opacity-10"
                data-wow-duration="1s"
                data-wow-delay="1s"
                alt=""
              />
              {/* <div className="xl:h36 text-[16px] absolute bottom-8 left-[50%] -translate-x-[50%] text-[#fff]">
                {tt("home.two.name")}
              </div> */}
            </div>
            <div
              className="two-img2 relative wow animate__slideInUp -left-[22px]"
              data-wow-duration="1s"
            >
              <img
                src={source.home.homeImg03}
                className="w-full wow  opacity-10 animate_opacity"
                data-wow-duration="1s"
                data-wow-delay="1.1s"
                alt=""
              />
              {/* <div className="xl:h36 text-[16px]  bottom-8 absolute left-[50%] -translate-x-[50%] text-[#fff]">
                {tt("home.two.name")}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
