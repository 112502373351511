import { Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsMoile } from "@/hooks/index";
export default function Page({
  pageList = [4, 8, 16, 32],
  total,
  onChange,
}: any) {
  const tt = useTranslation().t;
  const [pageSize, setPageSize] = useState(pageList[0]);
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageNo, setPageNo] = useState<number>(1);
  const isMobild = useIsMoile();

  const pageSizeChange = (value: number) => {
    setPageSize(value);
  };
  useEffect(() => {
    setPageNum(Math.ceil(total / pageSize));
    setPageNo(1);
    onChange(1, pageSize);
  }, [total, pageSize]);

  const pageNoChange = (isAdd: boolean) => {
    let no: number = pageNo;
    if (isAdd) {
      no = pageNo < pageNum ? pageNo + 1 : pageNum;
    } else {
      no = pageNo > 1 ? pageNo - 1 : 1;
    }
    setPageNo(no);
    onChange(no, pageSize);
  };

  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        {!isMobild && (
          <div className="text-[#fff] mr-[10px] p16">{tt("common.page")}</div>
        )}
        <div>
          <Select
            value={pageSize}
            style={{ width: "60px" }}
            onChange={pageSizeChange}
          >
            {pageList.map((item: any) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="text-[#fff] cursor-pointer">
        <span>1{pageNum ? "-" + pageNum : ""}</span>
        <span className="mx-[5px]">{tt("common.of")}</span>
        <span>{pageNo}</span>
        <span
          className="iconfont icon-xiangshangjiantou-copy-copy text-[14px] ml-[20px]"
          onClick={() => {
            pageNoChange(false);
          }}
        ></span>
        <span
          className="iconfont icon-xiangshangjiantou-copy text-[14px] ml-4"
          onClick={() => {
            pageNoChange(true);
          }}
        ></span>
      </div>
    </div>
  );
}
