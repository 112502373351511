import source from "@/utils/soucre";
import "./index.css";
import { useTranslation } from "react-i18next";
import Button from "@/components/button";
import { Link } from "react-router-dom";
export default function BlockThree({ isVisible }: any) {
  const tt = useTranslation().t;

  return (
    <div
      className="bg-cover"
      style={{
        backgroundImage: `url(${source.home.homeB04})`,
        backgroundSize: "100% 100%",
      }}
    >
      <div className="wow animate__fadeInUp" data-wow-duration="3s">
        {/* Why us */}
        <div className="h48 text-[#fff] text-center  weight-b pt-[100px] mb-[16px]">
          {tt("home.four.text1")}
        </div>
        {/* Regulated, AI-Powered, and Secure – Expanding Your Horizons with Endless Opportunities */}
        <div className="text-center">
          <div className="p20 textColor_white ">{tt("home.four.text2")}</div>
        </div>
        <div className="max-w-[1329px] xl:flex xl:justify-between mx-auto pb-[180px]">
          {/* 卡片1 */}
          <div className="mx-auto xl:w-auto w-[75vw] relative mt-[40px]">
            <div className="animate_line">
              <img src={source.home.fourBg01} alt="" />
            </div>
            <div className="absolute  bottom-0 p-[32px] z-10">
              <div className="h20 text-[#fff]">
                {tt("home.four.listTitle1")}
              </div>
              <div className="p16 text-[rgba(255,255,255,0.8)] mb-[16px] line-clamp-2">
                {tt("home.four.listContent1")}
              </div>
              <Link to={"/cusPage/16"}>
                <Button
                  type={1}
                  style={{
                    padding: "8px 20px",
                  }}
                >
                  <div className="p18 text-[#fff]">
                    {tt("home.four.btn")}
                    <span className="iconfont icon-xiangshangjiantou-copy  mr-[4px] text-[12px] rotate-90 ml-[2px]"></span>
                  </div>
                </Button>
              </Link>
            </div>
          </div>
          {/* 卡片2 */}
          <div className="mx-auto xl:w-auto w-[75vw] relative mt-[40px] xl:mx-[20px]">
            <div className="animate_line">
              <img src={source.home.fourBg02} alt="" />
            </div>
            <div className="absolute  bottom-0 p-[32px] z-10">
              <div className="h20 text-[#fff]">
                {tt("home.four.listTitle2")}
              </div>
              <div className="p16 text-[rgba(255,255,255,0.8)] mb-[16px] line-clamp-2">
                {tt("home.four.listContent2")}
              </div>
              <Link to={"/cusPage/16"}>
                <Button
                  type={1}
                  style={{
                    padding: "8px 20px",
                  }}
                >
                  <div className="p18 text-[#fff]">
                    {tt("home.four.btn")}
                    <span className="iconfont icon-xiangshangjiantou-copy  mr-[4px] text-[12px] rotate-90 ml-[2px]"></span>
                  </div>
                </Button>
              </Link>
            </div>
          </div>
          {/* 卡片3 */}
          <div className="mx-auto xl:w-auto w-[75vw] relative mt-[40px]">
            <div className="animate_line">
              <img src={source.home.fourBg03} alt="" />
            </div>
            <div className="absolute  bottom-0 p-[32px] z-10">
              <div className="h20 text-[#fff]">
                {tt("home.four.listTitle3")}
              </div>
              <div className="p16 text-[rgba(255,255,255,0.8)] mb-[16px] line-clamp-2">
                {tt("home.four.listContent3")}
              </div>
              <Link to={"/cusPage/16"}>
                <Button
                  type={1}
                  style={{
                    padding: "8px 20px",
                  }}
                >
                  <div className="p18 text-[#fff]">
                    {tt("home.four.btn")}
                    <span className="iconfont icon-xiangshangjiantou-copy  mr-[4px] text-[12px] rotate-90 ml-[2px]"></span>
                  </div>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
