import soure from "@/utils/soucre";
import { useTranslation } from "react-i18next";
import Item from "./components/item";
import Page from "@/components/page";
import { useLang } from "@/hooks";
import { useEffect, useState } from "react";
import { getCusPageList } from "@/api";
import Help from "./components/help";
import Question from "./components/quesion";

export default function HelpCenter() {
  const tt = useTranslation().t;

  return (
    <div
      className="w-full  bg-no-repeat pb-[100px] relative"
      style={{
        backgroundImage: `url(${soure.newBg})`,
        backgroundSize: "100%",
        backgroundColor: "#050506",
      }}
    >
      <div className="pt-[156px]">
        <div className="h48 text-center text-[#fff]">
          {tt("helpCenter.text1")}
        </div>
        <div className="text-center">
          <div className="p20 textColor_white mt-[16px]">
            {tt("helpCenter.text2")}
          </div>
        </div>
      </div>
      <Help></Help>
      <Question></Question>
    </div>
  );
}
