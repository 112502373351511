import source from "@/utils/soucre";
import { useTranslation } from "react-i18next";
import navList from "@/utils/menuData";
import "./index.scss";
import { useIsMoile } from "@/hooks";
import { sreenXL } from "@/utils/const";
import { Link } from "react-router-dom";

export default function Footer() {
  const tt = useTranslation().t;
  const isMoblie = useIsMoile(sreenXL);
  return (
    <div
      className="pb-[260px]"
      style={{
        backgroundImage: `url(${source.common.footer})`,
        backgroundSize: isMoblie ? "100%" : "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
      }}
    >
      <div className="w-[90vw] mx-auto pt-[64px] xl:flex text-center xl:text-left">
        <div className="xl:w-[30%]">
          <Link to="/home">
            <div className="icon-logo-xin iconfont text-[34px] text-[#fff]"></div>
          </Link>
          <div className="p14 text-[#919191] xl:w-[90%] w-[50%] mx-auto xl:ml-0">
            {tt("navbar.footer")}
          </div>
          <div className="logo_icon xl:mt-[80px] mt-[64px]">
            <a href="https://discord.gg/2QaafKmw" target="_blank">
              <span className="iconfont icon-a-MostUsedLogosWhiteBlack"></span>
            </a>
            <a
              href="https://www.facebook.com/share/1A7t5LvSc5/"
              target="_blank"
            >
              <span className="iconfont icon-a-MostUsedLogosWhiteBlack5"></span>
            </a>
            <a
              href="https://youtube.com/@aixg_official?si=y4USiuo4Sd3t_BFf"
              target="_blank"
            >
              <span className="iconfont icon-a-MostUsedLogosWhiteBlack1"></span>
            </a>
            <a
              href="https://www.facebook.com/share/1A7t5LvSc5/"
              target="_blank"
            >
              <span className="iconfont icon-a-MostUsedLogosWhiteBlack2"></span>
            </a>
            <a
              href="https://youtube.com/@aixg_official?si=y4USiuo4Sd3t_BFf"
              target="_blank"
            >
              <span className="iconfont icon-a-MostUsedLogosWhiteBlack3"></span>
            </a>
            <a href="https://t.me/+SWgWHm1HzNE2YzZl" target="_blank">
              <span className="iconfont icon-a-MostUsedLogosWhiteBlack4"></span>
            </a>
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-5 xl:w-[70%] cursor-pointer text-left">
          {navList.map((item) => {
            return item.children.length > 0 ? (
              <div key={item.name} className="sm:ml-[0px] ml-[30px]">
                <div className="p14 weight-b text-[#fff] mb-[10px] xl:mt-0 mt-[48px] ">
                  {tt(item.name)}
                </div>
                {item.children.map((ele) => (
                  <Link to={ele.url} key={ele.name}>
                    <div className="p14 text-[#919191] my-[5px]" key={ele.name}>
                      {tt(ele.name)}
                    </div>
                  </Link>
                ))}
              </div>
            ) : null;
          })}
        </div>
      </div>
    </div>
  );
}
