import soure from "@/utils/soucre";
import { useTranslation } from "react-i18next";
import PageBg from "@/components/pageBg";
import Item from "./components/item";
import { useEffect, useState } from "react";
import { getCusPageList } from "@/api/index";
import Page from "@/components/page";
const pageList = [12, 24, 32];
export default function Academy() {
  const tt = useTranslation().t;
  const [list, setList] = useState<any>([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(pageList[0]);
  const [total, setTotal] = useState(0);

  const getCusPageListFun = () => {
    getCusPageList({ pageNo, pageSize, type: "growp" }).then((res: any) => {
      setList(res.list || []);
      setTotal(res.total || 0);
    });
  };

  useEffect(() => {
    getCusPageListFun();
  }, [pageNo, pageSize]);

  const newChange = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
    setPageSize(pageSize);
  };

  return (
    <div
      className="w-full  bg-no-repeat pb-[100px] relative"
      style={{
        backgroundImage: `url(${soure.newBg})`,
        backgroundSize: "100%",
        backgroundColor: "#050506",
      }}
    >
      <div className=" absolute z-0">
        <img src={soure.academyBg} className="h-[1249px]" alt="" />
      </div>
      <div className="w-[90vw] mx-auto pt-[300px] relative z-1">
        <div className="mb-[106px]">
          <div className="h48 text-center text-[#fff]">
            {tt("academy.text1")}
          </div>
          <div className="text-center">
            <div className="p20 textColor_white mt-[16px]">
              {tt("academy.text2")}
            </div>
          </div>
        </div>
        {/* 内容 */}
        <div className="flex flex-col">
          <PageBg paddingClass="px-[30px]">
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[30px] mt-[40px]">
              {list.map((item: any) => (
                <Item key={item.id} item={item}></Item>
              ))}
            </div>
            <div className="mt-[20px] mx-auto mb-[40px] ml-[10px]">
              <Page
                onChange={newChange}
                total={total}
                pageList={pageList}
              ></Page>
            </div>
          </PageBg>
        </div>
      </div>
    </div>
  );
}
