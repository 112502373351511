import soure from "@/utils/soucre";
import { useTranslation } from "react-i18next";
import "swiper/css";
import Item from "./components/item";
import PageBg from "@/components/pageBg";
import Button from "./components/button";
import Bullet from "./components/bullet";
import "./index.scss";
import { useState } from "react";

const list = [1, 2, 3, 4, 5, 6];

export default function Privilege() {
  const tt = useTranslation().t;
  const [showBox, setShowBox] = useState(false);

  return (
    <div
      className="w-full  bg-no-repeat pb-[100px]"
      style={{
        backgroundImage: `url(${soure.newBg})`,
        backgroundSize: "100%",
        backgroundColor: "#050506",
      }}
    >
      <div className="xl:w-[85vw] w-[90vw] pt-[196px] mx-auto">
        {/* 标题 */}
        <div className="mb-[36px]">
          <div className="h48 text-center text-[#fff]">
            {tt("privilege.text1")}
          </div>
          <div className="text-center">
            <div className="p20 textColor_white mt-[16px]">
              {tt("privilege.text2")}
            </div>
          </div>
        </div>
        {/* 内容 */}
        <PageBg>
          <div className="flex flex-wrap justify-center mt-[75px]">
            {list.map((item: any) => (
              <Item item={item} key={item.id}></Item>
            ))}
          </div>
          <div className="w-[100%] mx-auto mt-[58px] mb-[38px]">
            <img src={soure.splitLine} className="w-[100%]" alt="" />
          </div>
          <div className="h36">{tt("privilege.title")}</div>
          <div className="h20 mt-[20px]">{tt("privilege.question1")}</div>
          <div className="p18 text-[rgba(255,255,255,0.5)]">
            {tt("privilege.answer1")}
          </div>
          <div className="h20 mt-[20px]">{tt("privilege.question2")}</div>
          <div className="p18 text-[rgba(255,255,255,0.5)]">
            {tt("privilege.answer2")}
          </div>
          <div className="h20 mt-[20px]">{tt("privilege.question3")}</div>
          <div className="p18 text-[rgba(255,255,255,0.5)]">
            {tt("privilege.answer3")}
          </div>
          <div className="mt-[36px] mb-[140px]">
            <Button type={2} onClick={() => setShowBox(true)}>
              {tt("privilege.btn")}
              <span className="iconfont icon-xiangshangjiantou-copy text-[12px]"></span>
            </Button>
          </div>
        </PageBg>
      </div>
      {showBox && (
        <Bullet
          close={() => {
            setShowBox(false);
          }}
        ></Bullet>
      )}
    </div>
  );
}
