import Button from "@/components/button";
import source from "@/utils/soucre";
import "./index.css";
import { useTranslation } from "react-i18next";
import { sreenXL } from "@/utils/const";
import { useIsMoile } from "@/hooks";
import videoUrl from "@/assets/banner_bg.mp4";
export default function BlockOne() {
  const tt = useTranslation().t;
  const isMoblie = useIsMoile(sreenXL);

  return (
    <div className="relative">
      <video
        loop
        autoPlay
        muted
        playsInline
        className="xl:w-[100vw] h-[100vh] object-cover"
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xl:w-[693px] w-[80vw]">
        {/* //标题动画*/}
        <div className="animate__animated animate__fadeInUp">
          <img src={source.home.homeW01} className="" alt="" />
          <div className="text-center">
            <div className="textColor_white p20">{tt("home.one.text1")}</div>
          </div>
        </div>
        {/* 按钮动画 */}
        <div className="animate__animated  animate__fadeInUp">
          <div className="xl:flex xl:justify-center text-center mt-[100px]">
            <div className="mt-[10px]">
              <Button type={2}> {tt("home.one.btn1")}</Button>
            </div>
            <div className="mt-[10px]">
              <Button
                type={1}
                style={{ marginLeft: !isMoblie ? "16px" : "0px" }}
              >
                <div className="p18 text-[#fff]">
                  <span className="iconfont icon-icons-1 mr-[4px] text-[22px]"></span>
                  {tt("home.one.btn2")}
                </div>
              </Button>
            </div>
          </div>
          <div className="p14 text-center mt-[20px] text-[#fff] opacity-50">
            {tt("home.one.text2")}
          </div>
          <div className="flex justify-center mt-[20px]">
            <div className="block-icon">
              <svg
                width="36"
                height="37"
                viewBox="0 0 36 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.5"
                  width="36"
                  height="36"
                  rx="4"
                  fill="white"
                  fill-opacity="0.2"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="35"
                  height="35"
                  rx="3.5"
                  stroke="white"
                  stroke-opacity="0.1"
                />
                <path
                  d="M19.6405 17.1425L26.0618 9.67822H24.5402L18.9645 16.1593L14.5113 9.67822H9.375L16.1092 19.4788L9.375 27.3062H10.8967L16.7848 20.462L21.4877 27.3062H26.624L19.6401 17.1425H19.6405ZM11.445 10.8238H13.7823L24.5408 26.2128H22.2036L11.445 10.8238Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="block-icon">
              <svg
                width="36"
                height="37"
                viewBox="0 0 36 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.5"
                  width="36"
                  height="36"
                  rx="4"
                  fill="white"
                  fill-opacity="0.2"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="35"
                  height="35"
                  rx="3.5"
                  stroke="white"
                  stroke-opacity="0.1"
                />
                <path
                  d="M7.71563 27.4334H11.5046V18.2316L6.0918 14.172V25.8096C6.0918 26.7081 6.81982 27.4334 7.71563 27.4334Z"
                  fill="#4285F4"
                />
                <path
                  d="M24.4954 27.4334H28.2844C29.1829 27.4334 29.9082 26.7054 29.9082 25.8096V14.172L24.4954 18.2316"
                  fill="#34A853"
                />
                <path
                  d="M24.4954 11.195V18.2316L29.9082 14.172V12.007C29.9082 9.99881 27.6159 8.854 26.011 10.0583"
                  fill="#FBBC05"
                />
                <path
                  d="M11.5046 18.2316L11.5045 11.195L17.9998 16.0665L24.4954 11.195V18.2316L17.9998 23.1032"
                  fill="#EA4335"
                />
                <path
                  d="M6.0918 12.007V14.172L11.5046 18.2316L11.5045 11.195L9.98901 10.0583C8.38141 8.854 6.0918 9.99881 6.0918 12.007Z"
                  fill="#C5221F"
                />
              </svg>
            </div>
            <div className="block-icon">
              <svg
                width="36"
                height="37"
                viewBox="0 0 36 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.5"
                  width="36"
                  height="36"
                  rx="4"
                  fill="white"
                  fill-opacity="0.2"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="35"
                  height="35"
                  rx="3.5"
                  stroke="white"
                  stroke-opacity="0.1"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.90883 18.5699C14.0478 16.3309 17.4745 14.8549 19.1891 14.1417C24.0846 12.1055 25.1018 11.7518 25.7649 11.7401C25.9107 11.7375 26.2367 11.7737 26.448 11.9451C26.6263 12.0898 26.6754 12.2853 26.6988 12.4225C26.7223 12.5597 26.7516 12.8722 26.7283 13.1164C26.463 15.9038 25.3151 22.6681 24.7311 25.7901C24.484 27.1111 23.9975 27.554 23.5264 27.5974C22.5027 27.6916 21.7254 26.9208 20.7339 26.2709C19.1824 25.2539 18.3059 24.6208 16.7999 23.6283C15.0595 22.4814 16.1877 21.851 17.1796 20.8208C17.4392 20.5512 21.9496 16.4487 22.0369 16.0765C22.0478 16.03 22.0579 15.8565 21.9549 15.7648C21.8518 15.6732 21.6997 15.7046 21.5899 15.7295C21.4343 15.7648 18.9557 17.403 14.1543 20.6441C13.4507 21.1272 12.8135 21.3626 12.2426 21.3503C11.6132 21.3367 10.4024 20.9944 9.50237 20.7018C8.39841 20.343 7.52101 20.1532 7.59741 19.5438C7.63721 19.2264 8.07434 18.9017 8.90883 18.5699Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
