import { ConfigProvider, Form, Input, message, Radio } from "antd";
import { useTranslation } from "react-i18next";
import Button from "../components/button";
import { createKOl } from "@/api";

export default function Bullet(props: any) {
  const tt = useTranslation().t;
  const [form] = Form.useForm();

  const sumbitFun = async () => {
    try {
      const values = await form.validateFields();
      createKOl(values).then((res) => {
        message.success("申请成功！");
        props.close();
      });
    } catch (errorInfo) {}
  };

  return (
    <div className="h-[100vh] w-[100vw] fixed top-0 left-0 black_opacity z-10">
      <div className="bullet_box_bg w-[80vw] h-[90vh] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
        <div
          className="iconfont icon-guanbi text-[#D9D9D9] absolute top-[30px] right-[50px] text-[24px]"
          onClick={() => {
            props.close();
          }}
        ></div>
        <div className="pt-[90px] text-[#fff] text-center">
          <div className="h36">{tt("privilege.bulletTitle")}</div>
          <div className="h20 mt-[72px]">{tt("privilege.baseInfo")}</div>
          <div className="w-[60%] mx-auto">
            <ConfigProvider
              theme={{
                components: {
                  Form: {
                    labelColor: "#fff",
                    labelFontSize: 18,
                    labelRequiredMarkColor: "#fff",
                  },
                },
              }}
            >
              <>
                <Form
                  name="layout-multiple-horizontal"
                  layout="horizontal"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  form={form}
                  scrollToFirstError={{
                    behavior: "instant",
                    block: "end",
                    focus: true,
                  }}
                >
                  <Form.Item
                    label={tt("privilege.form.name")}
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={tt("privilege.form.namePlaceholder")} />
                  </Form.Item>
                  <Form.Item
                    label={tt("privilege.form.email")}
                    name="email"
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={tt("privilege.form.emialPlaceholder")}
                    />
                  </Form.Item>
                  <Form.Item
                    label={tt("privilege.form.UID")}
                    name="uid"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={tt("privilege.form.UIDPlaceholder")} />
                  </Form.Item>
                  <Form.Item
                    label={tt("privilege.form.fansNumber")}
                    name="fans"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group style={{ width: "100%" }}>
                      <div className="form_box">
                        <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                          {tt("privilege.form.fansNumberOption1")}
                        </div>
                        <div>
                          <Radio value={1}></Radio>
                        </div>
                      </div>
                      <div className="form_box">
                        <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                          {tt("privilege.form.fansNumberOption2")}
                        </div>
                        <div>
                          <Radio value={2}></Radio>
                        </div>
                      </div>
                      <div className="form_box">
                        <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                          {tt("privilege.form.fansNumberOption3")}
                        </div>
                        <div>
                          <Radio value={3}></Radio>
                        </div>
                      </div>
                      <div className="form_box">
                        <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                          {tt("privilege.form.fansNumberOption4")}
                        </div>
                        <div>
                          <Radio value={4}></Radio>
                        </div>
                      </div>
                    </Radio.Group>
                  </Form.Item>
                  <div className="h20 mt-[72px]">{tt("privilege.proof")}</div>
                  <div className="p16 text-[rgba(255,255,255,0.5)]">
                    {tt("privilege.proofDesc")}
                  </div>
                  <Form.Item
                    label={tt("privilege.form.type")}
                    name="type"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group style={{ width: "100%" }}>
                      <div className="form_box">
                        <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                          {tt("privilege.form.typeOption1")}
                        </div>
                        <div>
                          <Radio value={1}></Radio>
                        </div>
                      </div>

                      <div className="form_box">
                        <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                          {tt("privilege.form.typeOption2")}
                        </div>
                        <div>
                          <Radio value={2}></Radio>
                        </div>
                      </div>

                      <div className="form_box">
                        <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                          {tt("privilege.form.typeOption3")}
                        </div>
                        <div>
                          <Radio value={3}></Radio>
                        </div>
                      </div>
                      <div className="form_box">
                        <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                          {tt("privilege.form.typeOption4")}
                        </div>
                        <div>
                          <Radio value={4}></Radio>
                        </div>
                      </div>
                      <div className="form_box">
                        <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                          {tt("privilege.form.typeOption5")}
                        </div>
                        <div>
                          <Radio value={5}></Radio>
                        </div>
                      </div>
                      <div className="form_box">
                        <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                          {tt("privilege.form.typeOption6")}
                        </div>
                        <div>
                          <Radio value={6}></Radio>
                        </div>
                      </div>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label={tt("privilege.form.media")}
                    name="media"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group style={{ width: "100%" }}>
                      <div className="flex">
                        <div className="form_box mr-[10px]">
                          <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                            <span className="text-[#fff] mr-[5px] iconfont icon-a-MostUsedLogosWhiteBlack6"></span>
                            {tt("privilege.form.mediaOption1")}
                          </div>
                          <div>
                            <Radio value={1}></Radio>
                          </div>
                        </div>
                        <div className="form_box">
                          <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                            <span className="text-[#fff] mr-[5px] iconfont icon-a-MostUsedLogosWhiteBlack11"></span>
                            {tt("privilege.form.mediaOption2")}
                          </div>
                          <div>
                            <Radio value={2}></Radio>
                          </div>
                        </div>
                      </div>

                      <div className="flex">
                        <div className="form_box mr-[10px]">
                          <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                            <span className="text-[#fff] mr-[5px] iconfont icon-a-MostUsedLogosWhiteBlack21"></span>
                            {tt("privilege.form.mediaOption3")}
                          </div>
                          <div>
                            <Radio value={3}></Radio>
                          </div>
                        </div>
                        <div className="form_box">
                          <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                            <span className="text-[#fff] mr-[5px] iconfont icon-a-MostUsedLogosWhiteBlack31"></span>
                            {tt("privilege.form.mediaOption4")}
                          </div>
                          <div>
                            <Radio value={4}></Radio>
                          </div>
                        </div>
                      </div>

                      <div className="flex">
                        <div className="form_box mr-[10px]">
                          <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                            <span className="text-[#fff] mr-[5px] iconfont icon-a-MostUsedLogosWhiteBlack41"></span>
                            {tt("privilege.form.mediaOption5")}
                          </div>
                          <div>
                            <Radio value={5}></Radio>
                          </div>
                        </div>
                        <div className="form_box">
                          <div className="text-[rgba(255,255,255,0.5)] text-center text-[14px]">
                          <span className="text-[#fff] mr-[5px] iconfont icon-Vector"></span>
                            {tt("privilege.form.mediaOption6")}
                          </div>
                          <div>
                            <Radio value={6}></Radio>
                          </div>
                        </div>
                      </div>
                    </Radio.Group>
                  </Form.Item>
                  <div className="mt-[36px] mb-[140px]">
                    <Button type={2} onClick={sumbitFun}>
                      Application Requirements
                    </Button>
                  </div>
                </Form>
              </>
            </ConfigProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
