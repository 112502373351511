import { ReactNode, useState } from "react";
import soucre from "@/utils/soucre";

export default function Button(props: any) {
  const { text, children, style } = props;

  return (
    <div
      className="p18 px-[58px] py-[15px] inline-block cursor-pointer rounded-sm overflow-hidden"
      onClick={props.onClick}
      style={{
        backgroundImage: `url(${soucre.privilegeBtn})`,
        backgroundSize: "100% 100%",
        ...style,
      }}
    >
      {text || children}
    </div>
  );
}
