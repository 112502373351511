import { useLang } from "@/hooks";
import { Link } from "react-router-dom";

export default function Item({ item }: any) {
  const lang = useLang();
  return (
    <Link to={"/announceDetail/" + item.id}>
      <div className="xl:flex text-[#fff] mt-[30px]">
        <div>
          <div className="h16 line-clamp-1 w-[200px]">
            {lang === "zh" ? item.title : item.enTitle}
          </div>
          <p className="p16 text-[rgba(255,255,255,0.5)]">2024.11.21</p>
        </div>
        <div className="line-clamp-3 p16 text-[rgba(255,255,255,0.5)] xl:mt-0 mt-[20px]">
          {lang === "zh" ? item.desc : item.enDesc}
        </div>
      </div>
    </Link>
  );
}
