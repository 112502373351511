import source from "@/utils/soucre";
import "./index.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as Icon1 } from "@/assets/home/five_icon01.svg";
import { ReactComponent as Icon2 } from "@/assets/home/five_icon02.svg";
import { ReactComponent as Icon3 } from "@/assets/home/five_icon03.svg";
import { ReactComponent as Icon4 } from "@/assets/home/five_icon04.svg";
import { useIsMoile } from "@/hooks";
import { sreenXL } from "@/utils/const";
import Button from "@/components/button";

const LogoList = [
  source.home.homeLogo1,
  source.home.homeLogo2,
  source.home.homeLogo3,
  source.home.homeLogo4,
  source.home.homeLogo5,
  source.home.homeLogo6,
  source.home.homeLogo7,
  source.home.homeLogo8,
];
export default function BlockSeven() {
  const tt = useTranslation().t;
  const isMoile = useIsMoile(sreenXL);
  return (
    <div
      className="relative -mt-[1px] pb-[335px] z-0"
    >
      <div className="relative z-10">
        <div className="wow animate_opacity" data-wow-duration="2s">
          {/* Roadmap 2025 Annual Outlook */}
          <div className="h48 text-[#fff] text-center  weight-b pt-[100px] pb-[16px]">
            {tt("home.seven.text1")}
          </div>
          {/* Regulated, AI-Powered, and Secure – Expanding Your Horizons with Endless Opportunities */}
          <div className="text-center w-[80vw] mx-auto">
            <div className="p20 text-white">{tt("home.seven.text2")}</div>
          </div>

          {/* logo */}
          <div className="flex justify-center  flex-wrap items-center mt-[20px]">
            {LogoList.map((item, index) => (
              <div key={index} className="m-[32px]">
                <img className="w-[120px]" src={item} alt="" />
              </div>
            ))}
          </div>
        </div>
        {/* 按钮 */}
        <div
          className="xl:flex xl:justify-center text-center mt-[10px] wow animate__fadeInUp"
          data-wow-duration="2s"
        >
          <div className="mt-[10px]">
            <Button type={2}>
              <span>{tt("home.seven.btn1")}</span>{" "}
              <span className="iconfont icon-xiangshangjiantou-copy text-[13px]"></span>
            </Button>
          </div>
          <div className="mt-[10px] xl:ml-[20px]">
            <Button type={1}>
              <div className="p18 text-[#fff] px-[40px]">
                {tt("home.seven.btn2")}
              </div>
            </Button>
          </div>
        </div>
      </div>
      <img src={source.home.homeB07} className="w-[120vw] absolute bottom-0 z-0 wow rotate30" alt="" />
    </div>
  );
}
