import { Routes, Route, Navigate } from "react-router-dom";
import Home from "@/pages/home";
import News from "@/pages/news";
import Announcements from "@/pages/announcements";
import Hire from "@/pages/hire";
import Privilege from "@/pages/privilege";
import Academy from "@/pages/academy";
import HelpCenter from "@/pages/helpCenter";
import CusPage from "@/pages/pageDetail";
import NewPage from "@/pages/newDetail";
import AnnounceDetail from "@/pages/announceDetail";
import HireDetail from "@/pages/hireDetail";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/news" element={<News />} />
      <Route path="/announcements" element={<Announcements />} />
      <Route path="/hire" element={<Hire />} />
      <Route path="/privilege" element={<Privilege />} />
      <Route path="/academy" element={<Academy />} />
      <Route path="/helpCenter" element={<HelpCenter />} />
      <Route path="/helpCenter" element={<HelpCenter />} />
      <Route path="/cusPage/:id" element={<CusPage />} />
      <Route path="/newDetail/:id" element={<NewPage />} />
      <Route path="/announceDetail/:id" element={<AnnounceDetail />} />
      <Route path="/hireDetail/:id" element={<HireDetail />} />
    </Routes>
  );
}
