const data = {
  text1: "招聘职位",
  text2:
    "200多名员工正在搭建从旧金融世界到新金融世界的桥梁",
  text3: "You'll be working at the forefront of advancing cryptocurrencies",
  text4:
    "Over 200 employees are building bridges from the old financial world to the new.AIXG harbors a strong sense of mission and is fully committed to creating gateways for people around the globe to support and advance cryptocurrencies.At AIXG, you'll be working at the forefront of advancing cryptocurrencies in the mainstream, ensuring that the space is understood, easily accessible, and trusted, and AIXG does this every day by delivering innovative products, education, and industry-leading security.",
  text5: "Enter your email address",
  text6: "Apply now",
  text7: "Our Mission",
  text8:
    "AiXG’s mission is to accelerate the popularity of cryptocurrencies globally so that everyone has the opportunity to achieve financial freedom and full participation.",
  errorTip: "Email cannot be empty",
  succussTip:
    "We have received your application, please be patient and wait for the email message!",
};
export default data;
