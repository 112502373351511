import React, { useEffect, useState } from "react";
import Router from "@/router/index";
import Header from "@/components/header";
import "animate.css";
import Footer from "@/components/footer";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header></Header>
        <Router />
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
