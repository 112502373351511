import { useTranslation } from "react-i18next";
import Item from "./item";
import Page from "@/components/page";
import { useEffect, useState } from "react";
import { getCusPageList } from "@/api";
const pageList = [12, 24, 32];
export default function HelpCenter() {
  const tt = useTranslation().t;
  const [pageNo, setPageNo] = useState(1);
  const [list, setList] = useState([]);
  const [pageSize, setPageSize] = useState(4);
  const [total, setTotal] = useState(0);

  const newChange = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
    setPageSize(pageSize);
  };

  useEffect(() => {
    getCusPageListFun();
  }, [pageNo, pageSize]);

  const getCusPageListFun = () => {
    getCusPageList({ pageNo, pageSize, type: "help" }).then((res: any) => {
      setList(res.list || []);
      setTotal(res.total || 0);
    });
  };

  return (
    <div className="w-[90vw] mx-auto mt-[80px]">
      {/* 内容 */}
      <div className="flex flex-col bullet_box_bg text-[#fff] px-[60px]">
        <div className="h32 text-center mt-[60px] mb-[30px]">
          {tt("helpCenter.text3")}
        </div>
        <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-[20px]">
          {list.map((item:any) => (
            <Item item={item} key={item.id}></Item>
          ))}
        </div>
        <div className="mt-[30px] mb-[40px]">
          <Page onChange={newChange} pageList={pageList} total={total}></Page>
        </div>
      </div>
    </div>
  );
}
