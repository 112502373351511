import source from "@/utils/soucre";
import "./index.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as Icon1 } from "@/assets/home/five_icon01.svg";
import { ReactComponent as Icon2 } from "@/assets/home/five_icon02.svg";
import { ReactComponent as Icon3 } from "@/assets/home/five_icon03.svg";
import { ReactComponent as Icon4 } from "@/assets/home/five_icon04.svg";
import { useIsMoile } from "@/hooks";
import { sreenXL } from "@/utils/const";
import { useEffect } from "react";
import Svg from "./svg";

export default function BlockFive() {
  const tt = useTranslation().t;
  const isMoile = useIsMoile(sreenXL);
  useEffect(() => {}, []);
  return (
    <div
      className="relative -mt-[1px]"
      style={{
        backgroundImage: `url(${source.home.homeB05})`,
        backgroundSize: "100% 100%",
      }}
    >
      <div className="wow animate__fadeIn" data-wow-duration="4s">
        {/* Roadmap 2025 Annual Outlook */}
        <div className="h48 text-[#fff] text-center  weight-b pt-[100px] pb-[16px]">
          {tt("home.five.text1")}
        </div>
        {/* Regulated, AI-Powered, and Secure – Expanding Your Horizons with Endless Opportunities */}
        <div className="text-center w-[80vw] mx-auto">
          <div className="p20 textColor_white ">{tt("home.five.text2")}</div>
        </div>
      </div>
      <div className="relative">
        <div className="xl:w-[90vw] w-full mx-auto wow animate__fadeInUp relative svg_anime"></div>
        <img
          src={source.home.fiveImg02}
          data-wow-duration="2s"
          className="absolute -top-[250px] left-[5vw] w-[45vw]  wow animate__fadeInLeft"
          alt=""
        />
      </div>
      <div className="relative -top-[50px]">
        <Svg></Svg>
      </div>
      <div
        className="xl:max-w-[1598px] grid xl:grid-cols-4 grid-cols-2 mx-auto relative top-[0px] wow animate__fadeInUp"
        style={{
          backgroundImage: isMoile ? `url(${source.home.fiveMoblieBg})` : "",
          backgroundSize: "100% 100%",
          padding: isMoile ? "30px" : "",
        }}
        data-wow-duration="3s"
      >
        {isMoile && <div className="line_trans_both absolute top-0"></div>}
        {isMoile && <div className="line_trans_both absolute bottom-0"></div>}
        {/* 卡片1 */}
        <div
          className={[
            "relative border-[rgba(225,255,255,0.1)] py-[30px] px-[10px]",
            isMoile ? "" : "border-t-[1px]",
          ].join(" ")}
        >
          <div className="pl-[20px]">
            <Icon4></Icon4>
            <div className="h20 mt-[10px] text-[#fff]">
              {tt("home.five.listTitle1")}
            </div>
            <div className="p14 mt-[10px] text-[#B0B0B0] pb-[60px]">
              {tt("home.five.listContent1")}
            </div>
          </div>
          <div
            className={[
              "absolute right-0",
              isMoile
                ? "h-[100%] top-0 line_trans"
                : "w-[1px] bg-[rgba(225,255,255,0.1)] h-[120%] -top-[30px]",
            ].join(" ")}
          ></div>
        </div>
        {/* 卡片2 */}
        <div
          className={[
            "relative border-[rgba(225,255,255,0.1)] py-[30px] px-[10px]",
            isMoile ? "" : "border-t-[1px]",
          ].join(" ")}
        >
          <div className="pl-[20px]">
            <Icon1></Icon1>
            <div className="h20 mt-[10px] text-[#fff]">
              {tt("home.five.listTitle2")}
            </div>
            <div className="p14 mt-[10px] text-[#B0B0B0] pb-[60px]">
              {tt("home.five.listContent2")}
            </div>
          </div>
          <div
            className={[
              "absolute right-0",
              isMoile
                ? "h-[0%] top-0 line_trans"
                : "w-[1px] bg-[rgba(225,255,255,0.1)] h-[120%] -top-[30px]",
            ].join(" ")}
          ></div>
        </div>
        {/* 卡片3 */}
        <div
          className={[
            "relative border-[rgba(225,255,255,0.1)] py-[30px] px-[10px]",
            isMoile ? "" : "border-t-[1px]",
          ].join(" ")}
        >
          <div className="pl-[20px]">
            <Icon3></Icon3>
            <div className="h20 mt-[10px] text-[#fff] relative">
              {tt("home.five.listTitle3")}
              <div className=" absolute right-[70px] -top-[15px]">
                <img src={source.home.label01} alt="" />
              </div>
            </div>
            <div className="p14 mt-[10px] text-[#B0B0B0] pb-[60px]">
              {tt("home.five.listContent3")}
            </div>
          </div>
          <div
            className={[
              "absolute right-0",
              isMoile
                ? "h-[100%] top-0 line_trans_r"
                : "w-[1px] bg-[rgba(225,255,255,0.1)] h-[120%] -top-[60px]",
            ].join(" ")}
          ></div>
          {isMoile && (
            <div
              className={["absolute top-0 right-0 line_trans_xr"].join(" ")}
            ></div>
          )}
        </div>
        {/* 卡片4 */}
        <div
          className={[
            "relative border-[rgba(225,255,255,0.1)] py-[30px] px-[10px]",
            isMoile ? "" : "border-t-[1px]",
          ].join(" ")}
        >
          <div className="pl-[20px]">
            <Icon2></Icon2>
            <div className="h20 mt-[10px] text-[#fff] relative">
              {tt("home.five.listTitle4")}
              <div className=" absolute right-[45px] -top-[15px]">
                <img src={source.home.label02} alt="" />
              </div>
            </div>
            <div className="p14 mt-[10px] text-[#B0B0B0] pb-[30px]">
              {tt("home.five.listContent4")}
            </div>
          </div>
          <div
            className={[
              "absolute right-0",
              isMoile
                ? "h-[0%] top-0 line_trans"
                : "w-[1px] bg-[rgba(225,255,255,0.1)] h-[120%] -top-[30px]",
            ].join(" ")}
          ></div>
          {isMoile && (
            <div
              className={["absolute top-0 right-0 line_trans_x"].join(" ")}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}
