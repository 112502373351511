const navList = [
  {
    name: "navbar.nav0",
    url: "",
    icon: "icon-icons1",
    children: [],
  },
  {
    name: "navbar.nav1",
    url: "",
    icon: "icon-icons",
    children: [
      {
        name: "navbar.nav10",
        url: "/cusPage/2",
      },
      {
        name: "navbar.nav11",
        url: "/cusPage/3",
      },
      {
        name: "navbar.nav12",
        url: "/hire",
      },
      {
        name: "navbar.nav13",
        url: "/news",
      },
      {
        name: "navbar.nav14",
        url: "/privilege",
      },
    ],
  },
  {
    name: "navbar.nav2",
    url: "",
    icon: "icon-icons-1",
    children: [
      {
        name: "navbar.nav20",
        url: "",
      },
      {
        name: "navbar.nav21",
        url: "",
      },
      {
        name: "navbar.nav22",
        url: "",
      },
      {
        name: "navbar.nav23",
        url: "",
      },
      {
        name: "navbar.nav24",
        url: "",
      },
    ],
  },
  {
    name: "navbar.nav3",
    url: "",
    icon: "icon-icons-2",
    children: [
      {
        name: "navbar.nav30",
        url: "/cusPage/4",
      },
      {
        name: "navbar.nav31",
        url: "/cusPage/5",
      },
      {
        name: "navbar.nav32",
        url: "/cusPage/6",
      },
      {
        name: "navbar.nav33",
        url: "/cusPage/7",
      },
      {
        name: "navbar.nav34",
        url: "/announcements",
      },
    ],
  },
  {
    name: "navbar.nav4",
    url: "",
    icon: "icon-icons-2",
    children: [
      {
        name: "navbar.nav40",
        url: "/cusPage/1",
      },
      {
        name: "navbar.nav41",
        url: "/cusPage/8",
      },
      {
        name: "navbar.nav42",
        url: "/cusPage/9",
      },
      {
        name: "navbar.nav43",
        url: "/cusPage/10",
      },{
        name: "navbar.nav44",
        url: "/cusPage/11",
      },
    ],
  },
  {
    name: "navbar.nav5",
    url: "",
    icon: "icon-icons-4",
    children: [
      {
        name: "navbar.nav50",
        url: "/academy",
      },
      {
        name: "navbar.nav51",
        url: "/helpCenter",
      },
      {
        name: "navbar.nav52",
        url: "/helpCenter",
      },
      {
        name: "navbar.nav53",
        url: "/cusPage/12",
      },
    ],
  },
];

export default navList;
