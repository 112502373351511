import http from "./https";

/**
 * 获取新闻列表
 *
 */
export const getNewsList = (params: any) => {
  return http.get({
    url: "/api/new/list",
    params,
  });
};

/**
 * 新闻banner
 *
 */
export const getHotList = (params: any) => {
  return http.get({
    url: "/api/new/hot",
    params,
  });
};

/**
 * 新闻详情
 *
 */
export const getNewDetail = (params: any) => {
  return http.get({
    url: "/api/new/detail",
    params,
  });
};

/**
 * 新闻banner
 *
 */
export const getNewBanner = (params: any) => {
  return http.get({
    url: "/api/new/banner",
    params,
  });
};

/**
 * 自定义页面详情
 *
 */
export const getCusPageDetail = (params: any) => {
  return http.get({
    url: "/api/cuspage/detail",
    params,
  });
};

/**
 * 自定义列表
 *
 */
export const getCusPageList = (params: any) => {
  return http.get({
    url: "/api/cusPage/list",
    params,
  });
};

/**
 * 创建kol
 *
 */
export const createKOl = (params: any) => {
  return http.post({
    url: "/api/kol/create",
    data: params,
  });
};

/**
 * 获取公告分类
 *
 */
export const getClassList = (params: any) => {
  return http.get({
    url: "/api/announce/class",
    params,
  });
};

/**
 * 获取公告列表
 *
 */
export const getAnnounceList = (params: any) => {
  return http.get({
    url: "/api/announce/list",
    params,
  });
};

/**
 * 获取公告详情
 *
 */
export const getAnnounceDetail = (params: any) => {
  return http.get({
    url: "/api/announce/detail",
    params,
  });
};

/**
 * 获取职位列表
 *
 */
export const getHireList = (params: any) => {
  return http.get({
    url: "/api/hire/list",
    params,
  });
};

/**
 * 获取职位详情
 *
 */
export const getHireDetail = (params: any) => {
  return http.get({
    url: "/api/hire/detail",
    params,
  });
};

/**
 * 职位申请
 *
 */
export const applyHire = (params: any) => {
  return http.post({
    url: "/api/email/create",
    data: params,
  });
};
