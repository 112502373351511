import homeB01 from "@/assets/home/bg01.jpg";
import homeB02 from "@/assets/home/bg02.jpg";
import homeB03 from "@/assets/home/bg03.jpg";
import homeB04 from "@/assets/home/bg04.jpg";
import homeB05 from "@/assets/home/bg05.jpg";
import homeB06 from "@/assets/home/bg06.jpg";
import homeB07 from "@/assets/home/bg07.jpg";
import homeW01 from "@/assets/home/word01.png";
import btnB01 from "@/assets/common/btn_bg01.png";
import btnB02 from "@/assets/common/btn_bg02.png";
import btnB03 from "@/assets/common/btn_bg03.png";
import btnB04 from "@/assets/common/btn_bg04.png";
import btnB05 from "@/assets/common/btn_bg05.png";
import homeIcon01 from "@/assets/home/icon01.png";
import homeIcon02 from "@/assets/home/icon02.png";
import homeIcon03 from "@/assets/home/icon03.png";
import homeImg01 from "@/assets/home/img01.png";
import homeImg02 from "@/assets/home/img02.png";
import homeImg03 from "@/assets/home/img03.png";
import homeBthree01 from "@/assets/home/three_bg01.png";
import calendar from "@/assets/home/Calendar.png";
import homeTablebg from "@/assets/home/table_bg.png";
import downBtn from "@/assets/header/down_bg.png";
import test from "@/assets/home/test.png";
import line01 from "@/assets/home/line01.png";
import navbg from "@/assets/header/nav_bg.png";
import moblieBanner from "@/assets/home/moblie_banner.png";
import fourBg01 from "@/assets/home/four_bg01.png";
import fourBg02 from "@/assets/home/four_bg02.png";
import fourBg03 from "@/assets/home/four_bg03.png";
import fiveImg01 from "@/assets/home/five_img01.png";
import fiveImg02 from "@/assets/home/five_img02.png";
import fiveMoblieBg from "@/assets/home/moblieFive_bg.png";
import sixImg01 from "@/assets/home/six_img01.png";
import sixImg02 from "@/assets/home/six_img02.png";
import sixImg03 from "@/assets/home/six_img03.png";
import sixImg04 from "@/assets/home/six_img04.png";
import homeLogo1 from "@/assets/home/logo1.png";
import homeLogo2 from "@/assets/home/logo2.png";
import homeLogo3 from "@/assets/home/logo3.png";
import homeLogo4 from "@/assets/home/logo4.png";
import homeLogo5 from "@/assets/home/logo5.png";
import homeLogo6 from "@/assets/home/logo6.png";
import homeLogo7 from "@/assets/home/logo7.png";
import homeLogo8 from "@/assets/home/logo8.png";
import footer from "@/assets/home/footer.jpg";
import newBg from "@/assets/new_bg.jpg";
import splitLine from "@/assets/split_line.png";
import hireInputBg from "@/assets/hire_input_bg.png";
import pageEndLine from "@/assets/page_end_line.png";
import privilegeListBg from "@/assets/privilege_list_bg.png";
import privilegeBtn from "@/assets/privilege_btn.png";
import academyBg from "@/assets/academy_bg.png";
import itemLogo0 from "@/assets/home/item/item0.png";
import itemLogo1 from "@/assets/home/item/item1.png";
import itemLogo2 from "@/assets/home/item/item2.png";
import itemLogo3 from "@/assets/home/item/item3.png";
import itemLogo4 from "@/assets/home/item/item4.png";
import itemLogo5 from "@/assets/home/item/item5.png";
import itemLogo6 from "@/assets/home/item/item6.png";
import itemLogo7 from "@/assets/home/item/item7.png";
import itemLogo8 from "@/assets/home/item/item8.png";
import itemLogo9 from "@/assets/home/item/item9.png";
import itemLogo10 from "@/assets/home/item/item10.png";
import itemLogo11 from "@/assets/home/item/item11.png";
import label01 from "@/assets/home/label_01.png";
import label02 from "@/assets/home/label_02.png";
const data = {
  home: {
    homeB01,
    homeB02,
    homeB03,
    homeB04,
    homeB05,
    homeB06,
    homeB07,
    homeW01,
    homeIcon01,
    homeIcon02,
    homeIcon03,
    homeImg01,
    homeImg02,
    homeImg03,
    homeBthree01,
    calendar,
    homeTablebg,
    test,
    line01,
    moblieBanner,
    fourBg01,
    fourBg02,
    fourBg03,
    fiveImg01,
    fiveImg02,
    fiveMoblieBg,
    sixImg01,
    sixImg02,
    sixImg03,
    sixImg04,
    homeLogo1,
    homeLogo2,
    homeLogo3,
    homeLogo4,
    homeLogo5,
    homeLogo6,
    homeLogo7,
    homeLogo8,
    label01,
    label02,
  },
  common: {
    btnB01,
    btnB02,
    btnB03,
    btnB04,
    btnB05,
    footer,
  },
  header: {
    downBtn,
    navbg,
  },
  newBg,
  splitLine,
  hireInputBg,
  pageEndLine,
  privilegeListBg,
  privilegeBtn,
  academyBg,
};
export default data;

export const list1 = [
  itemLogo3,
  itemLogo0,
  itemLogo1,
  itemLogo2,
  itemLogo3,
  itemLogo5,
  itemLogo5,
];

export const list2 = [
  itemLogo6,
  itemLogo8,
  itemLogo9,
  itemLogo10,
  itemLogo11,
  itemLogo7,
  itemLogo6,
];
