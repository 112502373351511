import soure from "@/utils/soucre";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Item from "./components/item";
import { getClassList, getAnnounceList } from "@/api/index";
import { useEffect, useState } from "react";
import { useLang } from "@/hooks";
import Page from "@/components/page";
import { Empty } from "antd";

const pageList = [10, 20, 30];

export default function Announcements() {
  const tt = useTranslation().t;
  const [classList, setClassList] = useState([]);
  const [selectClass, setSelectClass] = useState();
  const [total, setTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(pageList[0]);
  const [list, setList] = useState([]);
  const lang = useLang();
  const annChange = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
    setPageSize(pageSize);
  };

  const getClassListFun = () => {
    getClassList({ type: "announce" }).then((res) => {
      setClassList(res || []);
      setSelectClass(res && res[0] && res[0].id);
    });
  };

  const getAnnounceListFun = () => {
    getAnnounceList({ classId: selectClass }).then((res) => {
      setList(res.list);
      setTotal(res.total);
    });
  };

  useEffect(() => {
    getClassListFun();
  }, []);

  useEffect(() => {
    getAnnounceListFun();
  }, [pageNo, pageSize]);

  useEffect(() => {
    setPageNo(1);
    setPageSize(pageList[0]);
    getAnnounceListFun();
  }, [selectClass]);

  return (
    <div
      className="w-full  bg-no-repeat pb-[100px]"
      style={{
        backgroundImage: `url(${soure.newBg})`,
        backgroundSize: "100%",
        backgroundColor: "#050506",
      }}
    >
      <div className="xl:w-[85vw] w-[90vw] pt-[196px] mx-auto">
        {/* 标题 */}
        <div className="mb-[36px]">
          <div className="h48 text-center text-[#fff]">
            {tt("announcements.text1")}
          </div>
          <div className="text-center">
            <div className="p20 textColor_white mt-[16px]">
              {tt("announcements.text2")}
            </div>
          </div>
        </div>
        {/* 内容 */}
        <div className="black_blur">
          <div className="black_opacity min-h-[100vh]">
            <div className="w-[80%] mx-auto text-[#fff] pt-[112px] flex">
              <div className="w-[25%]">
                {classList.map((item: any) => {
                  return (
                    <p
                      key={item.id}
                      className={[
                        "h16  leading-[44px] pl-[24px] max-w-[200px] line-clamp-1 cursor-pointer",
                        selectClass === item.id ? "select" : "",
                      ].join(" ")}
                      onClick={() => {
                        setSelectClass(item.id);
                      }}
                    >
                      {lang === "zh" ? item.name : item.enName}
                    </p>
                  );
                })}
              </div>
              <div className="w-[75%] border-l-[1px] border-[rgba(255,255,255,0.2)] pl-[40px]">
                {list.map((item: any) => (
                  <Item item={item} key={item.id}></Item>
                ))}
                {list.length === 0 && <Empty></Empty>}
                <div className="mt-[30px] mb-[60px]">
                  <Page
                    onChange={annChange}
                    total={total}
                    pageList={pageList}
                  ></Page>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
