import source from "@/utils/soucre";
import { useLang } from "@/hooks/index";
export default function HeaderItem({ item }: any) {
  const lang = useLang();
  return (
    <div className="text-[#fff]">
      <div className="h36">{lang === "zh" ? item.title : item.enTitle}</div>
      <div className="p16 mt-[10px]">{item.updateDate}</div>
      <div className=" w-[100%] h-[350px] overflow-hidden  mr-[30px] rounded-[8px] mt-[20px]">
        <img src={item.imgUrl} className="min-h-[350px]" alt="" />
      </div>
      <div className="mt-[20px] p16">
        {lang === "zh" ? item.desc : item.enDesc}
      </div>
    </div>
  );
}
