import homeW01 from "@/assets/home/word01.png";
import BlockOne from "./components/blockOne";
import BlockTwo from "./components/blockTwo";
import BlockThree from "./components/blockThree";
import BlockFour from "./components/blockFour";
import BlockFive from "./components/blockFive";
import BlockSix from "./components/blockSix";
import BlockSeven from "./components/blockSeven";
import { WOW } from "wowjs";
import { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 50,
      mobile: true,
      live: false,
    }).init();
  }, []);
  return (
    <div>
      <BlockOne></BlockOne>
      <BlockTwo></BlockTwo>
      <BlockThree></BlockThree>
      <BlockFour></BlockFour>
      <BlockFive></BlockFive>
      <BlockSix></BlockSix>
      <BlockSeven></BlockSeven>
    </div>
  );
}
