const data = {
  nav0: "Trading",

  nav1: "About AIXG",
  nav10: "About us",
  nav11: "Security",
  nav12: "We’re hiring",
  nav13: "Press and Media",
  nav14: "Privilege",

  nav2: "Products",
  nav20: "Contract",
  nav21: "Spot trading",
  nav22: "Leverage",
  nav23: "Options",
  nav24: "Ai Calculus",

  nav3: "Services",
  nav30: "Vip Service",
  nav31: "Listing application",
  nav32: "Rates",
  nav33: "Bank collaboration",
  nav34: "Announcements",

  nav4: "Compliance",
  nav40: "Legalization",
  nav41: "User agreement",
  nav42: "Privacy statement",
  nav43: "Risk warning",
  nav44: "Certifications",

  nav5: "AIX Academy",
  nav50: "Self-growth",
  nav51: "Help center",
  nav52: "Q&A",
  nav53: "Understanding Cryptocurrency",
  downApp: "Down APP",
  footer:
    "Unlock Your Coding Potential with Enigma Unlock Your Coding Potential with Enigma",
};
export default data;
