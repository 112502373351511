const data = {
  one: {
    text1: "使用Enigma Code ai释放您的编码潜力",
    btn1: "开始使用",
    btn2: "注册",
    text2: "或者使用",
  },
  two: {
    text1: "为您的角色选择一个",
    text2: "AI聊天",
    name: "Bob",
  },
  three: {
    text1: "市场",
    text2: "Enigma码的编码潜力",
    th01: "名称",
    th02: "价格",
    th03: "范围",
    th04: "趋势",
    th05: "贸易",
    top: "热门人物",
    seeAll: "查看全部",
  },
  four: {
    text1: "为什么选择我们",
    text2:
      "Regulated, AI-Powered, and Secure – Expanding Your Horizons with Endless Opportunities",
    listTitle1: "持牌运营",
    listTitle2: "AI孵化基金",
    listTitle3: "安全保障",
    listContent1:
      "AIXG已获批美国财政部金融监管MSB牌照",
    listContent2:
      "AiXG与各大基金合作，孵化创新AI项目，推动科技发展，创造更多优质应用场景及工具。",
    listContent3:
      "保护客户资产和隐私一直是我们的首要任务，前沿的安全技术团队采用国际最高金融安全级别，全面保障用户资产的安全性。",
    btn: "查看更多",
  },
  five: {
    text1: "标题2025年度前景展望",
    text2:
      "AIXG业务覆盖加密资产交易、金融衍生品、行业研究、AI投资孵化、内容服务等多个领域。作为全球领先的Web3.0门户，致力于为世界各地的加密货币投资者提供全面、安全且值得信赖的服务与支持。",
    listTitle1: "短期目标",
    listContent1:
      "2025产品上线万人公测，实现注册100，000+用户。",
    listTitle2: "中期目标",
    listContent2: "在 2 到 3 个月内达到500，000+用户。",
    listTitle3: "成长目标",
    listContent3:
      "在 6 个月内，将实现平台总交易额1，000，000，000 USD",
    label3: "(within six months)",
    listTitle4: "长期目标",
    listContent4:
      "在一年内做到头部领军交易所全球排名前20。通过持续的技术创新、安全保障、合规运营及优秀的用户服务，建立 AiXG 的行业领导地位。",
    label: "(within 1 year)",
  },
  six: {
    text1: "随时随地开放交易",
    text2: "全面支持iOS、Android、API",
    listTitle1: "Short-term goals",
    listContent1:
      "2025 Product goes live in public beta for 10,000 people and achieves 100,000+ registered users.",
    listTitle2: "Medium-term objectives",
    listContent2: "Reach 500,000+ users in 2 to 3 months.",
    listTitle3: "Growth targets",
    listContent3:
      "Within 6 months, a total platform turnover of 1,000,000,000 USD will be realized.",
    label3: "(within six months)",
    listTitle4: "Long-term objectives",
    listContent4:
      "Within one year , AiXG will be ranked among the top 20 leading exchanges in the world, and will establish AiXG's position as an industry leader through continuous technological innovation, security, compliance, and excellent user services.",
    label: "(within 1 year)",
    btn: "下载",
  },
  seven: {
    text1: "合作单位",
    text2: "Coding Potential with Enigma Code-ai",
    btn1: "注册",
    btn2: "登录",
  },
};

export default data;
