const data = {
  text1: "AiXG KOL特权计划",
  text2:
    "作为AiXG的KOL合作伙伴，您可以通过分享您的专属邀请码或邀请链接，邀请好友或社区成员注册并交易（包括现货、合约），从而获得丰厚的返佣。加入AiXG的KOL计划不仅帮助您增加收入，还能助您在业内建立更高的个人影响力。以下是KOL合作伙伴的专属特权和返佣机制。",
  listTitle1: "高达80%的返佣比例",
  listContent1:
    "AiXG提供业内领先的返佣比例，KOL合作伙伴最高可享受60%的返佣率。让您通过用户的每笔交易持续获得收益，实现“躺赚”双倍佣金。",
  listTitle2: "KOL IP 打造",
  listContent2:
    "AiXG支持KOL的个人品牌推广，通过IP宣传、专属活动推广，帮助合作伙伴提升在加密货币行业中的个人影响力。",
  listTitle3: "平台流量扶持",
  listContent3:
    "对于深度合作伙伴，AiXG将通过自有流量渠道扶持合作伙伴，助力其更快发展社区并获得更高曝光。",
  listTitle4: "专属客户经理",
  listContent4:
    "每位KOL合作伙伴将配备专属客户经理，提供7*24小时的全方位支持，确保在推广过程中获得及时的帮助和解答。",
  listTitle5: "AiXG直播特权",
  listContent5:
    "如果您对行情和行业知识有独到见解，AiXG将邀请您作为直播或AMA特邀嘉宾，进一步提高您的知名度。",
  listTitle6: "参与热门活动",
  listContent6:
    "带领您的粉丝参与站内活动，通过粉丝的出色表现获得更多奖励，增强与粉丝的互动和粘性。",
  title: "如何赚取佣金?",
  question1: "1.提交申请",
  answer1:
    "- 填写申请表格，AiXG团队将评估您的申请，符合标准后批准加入KOL计划。",
  question2: "2.发布邀请链接",
  answer2:
    "在您的AiXG账户内创建、管理和跟踪邀请链接的效果，方便实时查看推广进展。",
  question3: "3.坐等收益到账",
  answer3: "用户使用您的邀请链接注册并交易后，您将获得他们每笔交易的佣金收益。",
  btn: "立即申请",
  bulletTitle: "加入AIXG全球KOL合作伙伴，赚取更高奖金",
  baseInfo: "基本信息",
  form: {
    name: "姓名",
    namePlaceholder: "请输入姓名",
    email: "邮箱",
    emialPlaceholder: "请输入邮箱",
    media: "社交媒体",
    mediaOption1: "Twitter",
    mediaOption2: "YouTube",
    mediaOption3: "Facebook",
    mediaOption4: "TikTok",
    mediaOption5: "Instagram",
    mediaOption6: "KakaoTalk",
    UID: "UID",
    UIDPlaceholder: "请输入UID",
    fansNumber: "粉丝数量",
    fansNumberOption1: "1-1,000,000",
    fansNumberOption2: "1,000,000-5,000,000",
    fansNumberOption3: "5,000,000-10,000,000",
    fansNumberOption4: "More than 10,000,000",
    type: "类型",
    typeOption1: "Traffic Website",
    typeOption2: "Video Blogger",
    typeOption3: "Social Media",
    typeOption4: "Analyst",
    typeOption5: "Agent",
    typeOption6: "Secondary Agent",
  },
  proof: "影响力证明",
  proofDesc: "至少填写一项",
};
export default data;
