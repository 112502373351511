import React from "react";
import ReactDOM from "react-dom/client";
import "@/styles/index.css";
import "@/styles/fonts.css";
import App from "./App";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import nextLocal from "@/il18/index";
import { ConfigProvider, theme } from "antd";
const { darkAlgorithm } = theme;

i18n.use(initReactI18next).init({
  // 设置语言资源，可以根据需要引入其他语言文件
  resources: {
    en: {
      translation: {
        ...nextLocal.en,
      },
    },
    zh: {
      translation: {
        ...nextLocal.zh,
      },
    },
  },
  lng: "en", // 默认语言
  fallbackLng: "en", // 如果当前语言没有对应的翻译，将使用该语言作为备用
  interpolation: {
    escapeValue: false, // 不要对翻译的文本进行转义，以支持 HTML 标签
  },
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ConfigProvider theme={{ algorithm: darkAlgorithm }}>
    <App />
  </ConfigProvider>
);
