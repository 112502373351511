import { useTranslation } from "react-i18next";
import { Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import soucre from "@/utils/soucre";
import "./index.scss";
import NavItem from "./narItem";
import { useIsMoile } from "@/hooks";
import { sreenXL } from "@/utils/const";
import { CSSTransition } from "react-transition-group";
import navList from "@/utils/menuData";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Header() {
  const tt = useTranslation().t;
  const i18n = useTranslation().i18n;
  const [langText, setLanText] = useState("En");
  const langList: MenuProps["items"] = [
    {
      key: "en",
      label: "En",
    },
    {
      key: "zh",
      label: "中",
    },
  ];
  const isMobile = useIsMoile(sreenXL);
  const [selectIndex, setSelectIndex] = useState<null | number>();
  const [isShow, setIsShow] = useState(false);
  const location = useLocation();

  const langChange = (info: any) => {
    const lang = info.key;
    i18n.changeLanguage(lang);
    if (lang === "en") {
      setLanText("En");
    } else {
      setLanText("中");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="flex items-center justify-between absolute z-10 text-[#fff] h-[96px] w-[90vw] top-0px left-[50%] translate-x-[-50%]">
      {/* logo */}
      <Link to="/">
        <div className="icon-logo-xin iconfont text-[34px]"></div>
      </Link>
      {/* 导航 */}
      {isMobile ? (
        <CSSTransition
          in={isShow || !isMobile}
          timeout={1000}
          unmountOnExit
          classNames="fade"
        >
          <div
            className="navbar"
            style={{
              backgroundImage: isMobile ? `url(${soucre.header.navbg})` : "",
              backgroundSize: isMobile ? "100% 100%" : "auto",
              padding: isMobile && isShow ? "16px 36px" : "",
              overflow: isMobile ? "hidden" : "auto",
            }}
          >
            {navList.map((item, index) => (
              <NavItem
                key={item.name}
                name={item.name}
                icon={item.icon}
                list={item.children}
                index={index}
                selectIndex={selectIndex}
                click={(e: number) => {
                  setSelectIndex(e);
                }}
              ></NavItem>
            ))}
          </div>
        </CSSTransition>
      ) : (
        <div
          className="navbar"
          style={{
            backgroundImage: isMobile ? `url(${soucre.header.navbg})` : "",
            backgroundSize: isMobile ? "100% 100%" : "auto",
            padding: isMobile && isShow ? "16px 36px" : "",
          }}
        >
          {navList.map((item, index) => (
            <NavItem
              key={item.name}
              name={item.name}
              icon={item.icon}
              list={item.children}
              index={index}
              selectIndex={selectIndex}
              click={(e: number) => {
                setSelectIndex(e);
              }}
            ></NavItem>
          ))}
        </div>
      )}

      {/* 下载和中英文切换 */}
      <div className="flex items-center">
        <div className="mr-[16px]">
          <Dropdown
            menu={{
              items: langList,
              onClick: (e) => {
                langChange(e);
              },
            }}
            trigger={["click"]}
          >
            <Space>
              <span className="p14 font-pls_m">{langText}</span>
              <DownOutlined
                style={{
                  fontSize: "12px",
                }}
              />
            </Space>
          </Dropdown>
        </div>
        <div
          className="xl:p18 p14 bg-cover xl:px-[40px] px-[20px] py-[5px] xl:py-[10px] border-[1px] border-[rgba(255,255,255,30%)] rounded-[8px]"
          style={{ backgroundImage: `url(${soucre.header.downBtn})` }}
        >
          <span className="iconfont icon-icons-upload1 mr-[5px]"></span>
          <span>{tt("navbar.downApp")}</span>
        </div>
        {isMobile && (
          <div
            className="icon-caidan iconfont text-[34px] ml-[8px]"
            onClick={() => {
              isMobile && setIsShow(!isShow);
            }}
          ></div>
        )}
      </div>
    </div>
  );
}
