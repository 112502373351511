import { ReactNode, useState } from "react";
import soucre from "@/utils/soucre";
interface ButtonProps {
  text?: string;
  children?: ReactNode;
  type?: 1 | 2;
  color?: string;
  style?: Record<string, string>;
  onClick?: () => void;
  disabled?: boolean;
}

export default function Button(props: ButtonProps) {
  const { text, children, type = 1, color = "#fff", style } = props;

  const [hover, setHover] = useState(false);

  return (
    <button
      className={[
        "p18 px-[50px] py-[15px] inline-block cursor-pointer rounded-sm overflow-hidden relative",
      ].join(" ")}
      style={{
        backgroundImage: `url(${
          type === 1 ? soucre.common.btnB01 : soucre.common.btnB03
        })`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        color,
        ...style,
        opacity: props.disabled ? 0.5 : 1,
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <span className=" relative z-10">{text || children}</span>
      {hover && (
        <div
          className="absolute top-0 left-0 w-full h-full z-0 animate__animated animate__fadeIn "
          style={{
            backgroundImage: `url(${
              type === 1 ? soucre.common.btnB02 : soucre.common.btnB04
            })`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      )}
    </button>
  );
}
