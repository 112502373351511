import home from "./home";
import navbar from "./navbar";
import news from "./news";
import hire from "./hire";
import announcements from "./announcements";
import privilege from "./privilege";
import academy from "./academy";
import helpCenter from "./helpCenter";
import common from "./common";

const data = {
  home,
  navbar,
  news,
  hire,
  announcements,
  privilege,
  academy,
  helpCenter,
  common,
};

export default data;
