import { useEffect } from "react";

export default function Svg(props: any) {
  const anime: any = window.anime;
  useEffect(() => {
    scale();
    window.addEventListener("resize", scale);

    // 创建一个IntersectionObserver实例
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 元素进入视窗时的操作
          var path = anime.path("#path_line");
          anime({
            targets: ".icon_anime",
            translateX: path("x"),
            translateY: path("y"),
            easing: "linear",
            duration: 1000,
          });
        }
      });
    });

    // 选择需要观察的目标元素并开始观察
    const target: any = document.getElementById("svg_animte");
    observer.observe(target);
    return () => {
      window.removeEventListener("resize", scale);
    };
  });

  const scale = () => {
    const svg = document.getElementById("svg-1");
    const viewBox = svg?.getAttribute("viewBox");
    if (viewBox) {
      svg?.removeAttribute("width");
      svg?.removeAttribute("height");
      const width = Number(viewBox.split(" ")[2]);
      const height = Number(viewBox.split(" ")[3]);
      const bodyWidth = document.body.clientWidth;
      const setHeight = (height * bodyWidth) / width;
      svg?.setAttribute("height", `${setHeight}px`);
      svg?.setAttribute("width", `${bodyWidth}px`);
    }
  };
  return (
    <div className="relative -top-[100px]" id="svg_animte">
      <div className="icon_anime w-[0px] h-[0px]  left-0 -top-[3%] absolute">
        <svg
          width="86px"
          height="49px"
          viewBox="0 0 86 49"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          className=" absolute top-[0px] left-[0px]"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="-2.63157895%"
              x2="95.7894737%"
              y2="112.963158%"
              id="linearGradient-johhqvm2p_-1"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.4" offset="0%"></stop>
              <stop
                stop-color="#FFFFFF"
                stop-opacity="0.01"
                offset="40%"
              ></stop>
              <stop
                stop-color="#FFFFFF"
                stop-opacity="0.01"
                offset="60%"
              ></stop>
              <stop
                stop-color="#FFFFFF"
                stop-opacity="0.1"
                offset="100%"
              ></stop>
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="43.1795841%"
              x2="57.026087%"
              y2="60.1400983%"
              id="linearGradient-johhqvm2p_-2"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.4" offset="0%"></stop>
              <stop
                stop-color="#FFFFFF"
                stop-opacity="0.01"
                offset="40%"
              ></stop>
              <stop
                stop-color="#FFFFFF"
                stop-opacity="0.01"
                offset="60%"
              ></stop>
              <stop
                stop-color="#FFFFFF"
                stop-opacity="0.1"
                offset="100%"
              ></stop>
            </linearGradient>
          </defs>
          <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="编组-3" transform="translate(0.063000, 0.168100)">
              <g id="编组">
                <circle
                  id="椭圆形"
                  fill-opacity="0.01"
                  fill="#F8F8F8"
                  fill-rule="nonzero"
                  cx="10"
                  cy="10"
                  r="10"
                ></circle>
                <circle
                  id="椭圆形"
                  fill-opacity="0.3"
                  fill="#121212"
                  fill-rule="nonzero"
                  cx="10"
                  cy="10"
                  r="10"
                ></circle>
                <circle
                  id="椭圆形"
                  stroke="url(#linearGradient-johhqvm2p_-1)"
                  cx="10"
                  cy="10"
                  r="9.5"
                ></circle>
              </g>
              <g id="编组-2" transform="translate(5.000000, 5.000000)">
                <circle
                  id="椭圆形"
                  fill="#F8F8F8"
                  fill-rule="nonzero"
                  cx="5"
                  cy="5"
                  r="5"
                ></circle>
                <path
                  d="M9.083,7.183 C8.896,7.116 8.705,7.048 8.544,7.006 C8.392,6.966 8.097,6.9 7.781,7.01 C7.419,7.135 7.135,7.42 7.009,7.782 C6.9,8.098 6.965,8.392 7.005,8.545 C7.047,8.706 7.115,8.896 7.183,9.084 L11.957,22.383 C12.041,22.617 12.121,22.841 12.2,23.014 C12.267,23.159 12.42,23.478 12.751,23.659 C13.113,23.856 13.55,23.861 13.917,23.674 C14.253,23.502 14.414,23.188 14.484,23.044 C14.568,22.873 14.655,22.651 14.744,22.42 L16.893,16.894 L22.419,14.745 C22.651,14.655 22.872,14.569 23.043,14.485 C23.187,14.414 23.502,14.254 23.673,13.918 C23.861,13.55 23.855,13.114 23.658,12.752 C23.478,12.421 23.159,12.268 23.013,12.201 C22.84,12.122 22.616,12.041 22.383,11.958 L9.083,7.183 Z"
                  id="路径"
                  stroke="#F8F8F8"
                  stroke-width="1.5"
                  fill="#121212"
                  fill-rule="nonzero"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <g id="编组" transform="translate(21.000000, 21.000000)">
                  <rect
                    id="矩形"
                    fill-opacity="0.05"
                    fill="#F8F8F8"
                    fill-rule="nonzero"
                    // style="mix-blend-mode: luminosity;"
                    x="0"
                    y="0"
                    width="59"
                    height="22"
                    rx="8"
                  ></rect>
                  <rect
                    id="矩形"
                    stroke="url(#linearGradient-johhqvm2p_-2)"
                    stroke-width="1.5"
                    x="0.75"
                    y="0.75"
                    width="57.5"
                    height="20.5"
                    rx="7.25"
                  ></rect>
                  <path
                    d="M16.996,15 L16.996,7.55 L18.186,7.55 L22.186,12.82 L21.546,12.97 L21.546,7.55 L23.096,7.55 L23.096,15 L21.896,15 L17.966,9.69 L18.546,9.54 L18.546,15 L16.996,15 Z M28.148,15.12 C27.588,15.12 27.068,15.024 26.588,14.83 C26.108,14.637 25.688,14.367 25.328,14.02 C24.974,13.667 24.698,13.257 24.498,12.79 C24.298,12.324 24.198,11.817 24.198,11.27 C24.198,10.724 24.294,10.217 24.488,9.75 C24.688,9.284 24.964,8.877 25.318,8.53 C25.678,8.184 26.098,7.914 26.578,7.72 C27.058,7.527 27.581,7.43 28.148,7.43 C28.714,7.43 29.238,7.527 29.718,7.72 C30.198,7.914 30.614,8.184 30.968,8.53 C31.328,8.877 31.604,9.284 31.798,9.75 C31.998,10.217 32.098,10.724 32.098,11.27 C32.098,11.817 31.998,12.324 31.798,12.79 C31.598,13.257 31.318,13.667 30.958,14.02 C30.604,14.367 30.188,14.637 29.708,14.83 C29.228,15.024 28.708,15.12 28.148,15.12 Z M28.148,13.72 C28.488,13.72 28.801,13.66 29.088,13.54 C29.381,13.42 29.638,13.254 29.858,13.04 C30.078,12.82 30.248,12.56 30.368,12.26 C30.488,11.96 30.548,11.63 30.548,11.27 C30.548,10.91 30.488,10.584 30.368,10.29 C30.248,9.99 30.078,9.73 29.858,9.51 C29.638,9.29 29.381,9.124 29.088,9.01 C28.801,8.89 28.488,8.83 28.148,8.83 C27.808,8.83 27.491,8.89 27.198,9.01 C26.911,9.124 26.658,9.29 26.438,9.51 C26.218,9.73 26.048,9.99 25.928,10.29 C25.808,10.584 25.748,10.91 25.748,11.27 C25.748,11.63 25.808,11.96 25.928,12.26 C26.048,12.56 26.218,12.82 26.438,13.04 C26.658,13.254 26.911,13.42 27.198,13.54 C27.491,13.66 27.808,13.72 28.148,13.72 Z M34.402,15 L32.452,7.55 L34.132,7.55 L35.602,13.79 L35.152,13.79 L36.702,7.55 L38.302,7.55 L39.852,13.79 L39.402,13.79 L40.872,7.55 L42.548,7.55 L40.602,15 L38.802,15 L37.242,9 L37.762,9 L36.202,15 L34.402,15 Z"
                    id="形状"
                    fill-opacity="0.95"
                    fill="#F8F8F8"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className="svg_box">
        <svg
          width="100vw"
          height="588px"
          viewBox="0 0 1924 588"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          id="svg-1"
        >
          <defs>
            <linearGradient
              x1="41.9359288%"
              y1="49.0886172%"
              x2="84.558694%"
              y2="48.6403714%"
              id="linearGradient-_kc5qk8wd8-1"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
              <stop stop-color="#FFFFFF" offset="74.4999%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="49.9999428%"
              y1="47.3833371%"
              x2="52.1480427%"
              y2="52.570348%"
              id="linearGradient-_kc5qk8wd8-2"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
              <stop
                stop-color="#FFFFFF"
                stop-opacity="0.2"
                offset="100%"
              ></stop>
            </linearGradient>
            <linearGradient
              x1="49.9999428%"
              y1="0%"
              x2="49.9999428%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-3"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.4" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="49.9999428%"
              y1="0%"
              x2="49.9999428%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-4"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.4" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="49.9999428%"
              y1="0%"
              x2="49.9999428%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-5"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.4" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="49.9999428%"
              y1="0%"
              x2="49.9999428%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-6"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.6" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="49.9999428%"
              y1="0%"
              x2="49.9999428%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-7"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.4" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="49.9999428%"
              y1="0%"
              x2="49.9999428%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-8"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.4" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="49.9999428%"
              y1="0%"
              x2="49.9999428%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-9"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.4" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="49.9999428%"
              y1="0%"
              x2="49.9999428%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-10"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.4" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="50.0033743%"
              y1="47.3833371%"
              x2="52.1537619%"
              y2="52.570348%"
              id="linearGradient-_kc5qk8wd8-11"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
              <stop
                stop-color="#FFFFFF"
                stop-opacity="0.2"
                offset="100%"
              ></stop>
            </linearGradient>
            <linearGradient
              x1="50.0033743%"
              y1="0%"
              x2="50.0033743%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-12"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.4" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="50.0079496%"
              y1="47.3833371%"
              x2="52.1468989%"
              y2="52.570348%"
              id="linearGradient-_kc5qk8wd8-13"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
              <stop
                stop-color="#FFFFFF"
                stop-opacity="0.2"
                offset="100%"
              ></stop>
            </linearGradient>
            <linearGradient
              x1="50.0079496%"
              y1="0%"
              x2="50.0079496%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-14"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.4" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="49.9965113%"
              y1="47.3833371%"
              x2="52.1468989%"
              y2="52.570348%"
              id="linearGradient-_kc5qk8wd8-15"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
              <stop
                stop-color="#FFFFFF"
                stop-opacity="0.2"
                offset="100%"
              ></stop>
            </linearGradient>
            <linearGradient
              x1="49.9965113%"
              y1="0%"
              x2="49.9965113%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-16"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.4" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="47.4173554%"
              x2="52.125%"
              y2="52.5374483%"
              id="linearGradient-_kc5qk8wd8-17"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
              <stop
                stop-color="#FFFFFF"
                stop-opacity="0.2"
                offset="100%"
              ></stop>
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-18"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.5" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-19"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0.4" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="47.357643%"
              x2="52.1724138%"
              y2="52.5950588%"
              id="linearGradient-_kc5qk8wd8-20"
            >
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
              <stop
                stop-color="#FFFFFF"
                stop-opacity="0.2"
                offset="100%"
              ></stop>
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="linearGradient-_kc5qk8wd8-21"
            >
              <stop stop-color="#FFFFFF" offset="0%"></stop>
              <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
          </defs>
          <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="编组"
              transform="translate(0.218000, 0.352800)"
              fill-rule="nonzero"
            >
              <g id="Clipped">
                <path
                  d="M0,442.1503 C509.565,514.2993 979.502,501.1303 1327.383,421.4463 C1501.323,381.6063 1644.853,325.1133 1747.583,254.2523 C1850.323,183.3873 1912.423,98.0133 1923.013,0.4315 L1919.033,0 C1908.613,96.0323 1847.473,180.4913 1745.313,250.9593 C1643.153,321.4313 1500.143,377.7713 1326.493,417.5473 C979.202,497.0953 509.775,510.2893 0.561,438.1903 L0,442.1503 Z"
                  id="路径"
                  fill="url(#linearGradient-_kc5qk8wd8-1)"
                ></path>
                <path
                  d="M538.045635,487.966172 C793.949198,494.71214 1029.15748,478.166161 1230.33697,441.369796"
                  id="path_line"
                  // stroke="#FF0000"
                ></path>
              </g>
              <g transform="translate(524.409000, 239.815300)" id="矩形">
                <g opacity="0.2" transform="translate(0.000000, 287.000000)">
                  <g opacity="0.5">
                    <rect
                      fill-opacity="0.05"
                      fill="#F8F8F8"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                    <rect
                      fill-opacity="0.5"
                      fill="url(#linearGradient-_kc5qk8wd8-2)"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                  </g>
                  <g
                    transform="translate(0.000000, 24.000000)"
                    fill="url(#linearGradient-_kc5qk8wd8-3)"
                    fill-opacity="0.6"
                  >
                    <rect x="0" y="0" width="87.4261" height="36" rx="2"></rect>
                  </g>
                </g>
                <g opacity="0.2" transform="translate(95.426000, 277.000000)">
                  <g opacity="0.5">
                    <rect
                      fill-opacity="0.05"
                      fill="#F8F8F8"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                    <rect
                      fill-opacity="0.5"
                      fill="url(#linearGradient-_kc5qk8wd8-2)"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                  </g>
                  <g
                    transform="translate(0.000000, 24.000000)"
                    fill="url(#linearGradient-_kc5qk8wd8-4)"
                    fill-opacity="0.6"
                  >
                    <rect x="0" y="0" width="87.4261" height="46" rx="2"></rect>
                  </g>
                </g>
                <g opacity="0.25" transform="translate(190.852000, 267.000000)">
                  <g opacity="0.5">
                    <rect
                      fill-opacity="0.05"
                      fill="#F8F8F8"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                    <rect
                      fill-opacity="0.5"
                      fill="url(#linearGradient-_kc5qk8wd8-2)"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                  </g>
                  <g
                    transform="translate(0.000000, 24.000000)"
                    fill="url(#linearGradient-_kc5qk8wd8-5)"
                    fill-opacity="0.6"
                  >
                    <rect x="0" y="0" width="87.4261" height="56" rx="2"></rect>
                  </g>
                </g>
                <g opacity="0.3" transform="translate(286.280000, 257.000000)">
                  <g opacity="0.5">
                    <rect
                      fill-opacity="0.05"
                      fill="#F8F8F8"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                    <rect
                      fill-opacity="0.5"
                      fill="url(#linearGradient-_kc5qk8wd8-2)"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                  </g>
                  <g
                    transform="translate(0.000000, 24.000000)"
                    fill="url(#linearGradient-_kc5qk8wd8-6)"
                    fill-opacity="0.6"
                  >
                    <rect x="0" y="0" width="87.4261" height="66" rx="2"></rect>
                  </g>
                </g>
                <g opacity="0.35" transform="translate(381.705000, 247.000000)">
                  <g opacity="0.5">
                    <rect
                      fill-opacity="0.05"
                      fill="#F8F8F8"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                    <rect
                      fill-opacity="0.5"
                      fill="url(#linearGradient-_kc5qk8wd8-2)"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                  </g>
                  <g
                    transform="translate(0.000000, 24.000000)"
                    fill="url(#linearGradient-_kc5qk8wd8-7)"
                    fill-opacity="0.6"
                  >
                    <rect x="0" y="0" width="87.4261" height="76" rx="2"></rect>
                  </g>
                </g>
                <g opacity="0.4" transform="translate(477.131000, 237.000000)">
                  <g opacity="0.5">
                    <rect
                      fill-opacity="0.05"
                      fill="#F8F8F8"
                      x="0"
                      y="5.68434189e-14"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                    <rect
                      fill-opacity="0.5"
                      fill="url(#linearGradient-_kc5qk8wd8-2)"
                      x="0"
                      y="5.68434189e-14"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                  </g>
                  <g
                    transform="translate(0.000000, 24.000000)"
                    fill="url(#linearGradient-_kc5qk8wd8-8)"
                    fill-opacity="0.6"
                  >
                    <rect x="0" y="0" width="87.4261" height="86" rx="2"></rect>
                  </g>
                </g>
                <g opacity="0.45" transform="translate(572.557000, 227.000000)">
                  <g opacity="0.5" transform="translate(0.000000, 0.000000)">
                    <rect
                      fill-opacity="0.05"
                      fill="#F8F8F8"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                    <rect
                      fill-opacity="0.5"
                      fill="url(#linearGradient-_kc5qk8wd8-2)"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                  </g>
                  <g
                    transform="translate(0.000000, 24.000000)"
                    fill="url(#linearGradient-_kc5qk8wd8-9)"
                    fill-opacity="0.6"
                  >
                    <rect
                      x="0"
                      y="5.68434189e-14"
                      width="87.4261"
                      height="96"
                      rx="2"
                    ></rect>
                  </g>
                </g>
                <g opacity="0.5" transform="translate(667.983000, 217.000000)">
                  <g transform="translate(0.000000, 0.000000)">
                    <rect
                      fill-opacity="0.05"
                      fill="#F8F8F8"
                      x="1.13686838e-13"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                    <rect
                      fill-opacity="0.5"
                      fill="url(#linearGradient-_kc5qk8wd8-2)"
                      x="1.13686838e-13"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                  </g>
                  <g
                    transform="translate(0.000000, 24.000000)"
                    fill="url(#linearGradient-_kc5qk8wd8-10)"
                    fill-opacity="0.6"
                  >
                    <rect
                      x="1.13686838e-13"
                      y="5.68434189e-14"
                      width="87.4261"
                      height="106"
                      rx="2"
                    ></rect>
                  </g>
                </g>
                <g opacity="0.55" transform="translate(763.408000, 207.000000)">
                  <g opacity="0.5" transform="translate(0.000000, 0.000000)">
                    <rect
                      fill-opacity="0.05"
                      fill="#F8F8F8"
                      x="1.13686838e-13"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                    <rect
                      fill-opacity="0.5"
                      fill="url(#linearGradient-_kc5qk8wd8-11)"
                      x="1.13686838e-13"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                  </g>
                  <g
                    transform="translate(0.000000, 24.000000)"
                    fill="url(#linearGradient-_kc5qk8wd8-12)"
                    fill-opacity="0.6"
                  >
                    <rect
                      x="1.13686838e-13"
                      y="5.68434189e-14"
                      width="87.4261"
                      height="116"
                      rx="2"
                    ></rect>
                  </g>
                </g>
                <g opacity="0.6" transform="translate(858.834000, 188.000000)">
                  <g opacity="0.5" transform="translate(0.000000, 0.000000)">
                    <rect
                      fill-opacity="0.05"
                      fill="#F8F8F8"
                      x="-2.27373675e-13"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                    <rect
                      fill-opacity="0.5"
                      fill="url(#linearGradient-_kc5qk8wd8-13)"
                      x="-2.27373675e-13"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                  </g>
                  <g
                    transform="translate(0.000000, 24.000000)"
                    fill="url(#linearGradient-_kc5qk8wd8-14)"
                    fill-opacity="0.6"
                  >
                    <rect
                      x="-2.27373675e-13"
                      y="5.68434189e-14"
                      width="87.4261"
                      height="135"
                      rx="2"
                    ></rect>
                  </g>
                </g>
                <g opacity="0.65" transform="translate(954.264000, 157.000000)">
                  <g opacity="0.5" transform="translate(0.000000, 0.000000)">
                    <rect
                      fill-opacity="0.05"
                      fill="#F8F8F8"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                    <rect
                      fill-opacity="0.5"
                      fill="url(#linearGradient-_kc5qk8wd8-15)"
                      x="0"
                      y="0"
                      width="87.4261"
                      height="20"
                      rx="2"
                    ></rect>
                  </g>
                  <g
                    transform="translate(0.000000, 24.000000)"
                    fill="url(#linearGradient-_kc5qk8wd8-16)"
                    fill-opacity="0.8"
                  >
                    <rect
                      x="0"
                      y="5.68434189e-14"
                      width="87.4261"
                      height="166"
                      rx="2"
                    ></rect>
                  </g>
                </g>
                <g opacity="0.7" transform="translate(1049.684000, 118.000000)">
                  <g opacity="0.5" transform="translate(0.000000, 0.000000)">
                    <rect
                      fill-opacity="0.05"
                      fill="#F8F8F8"
                      x="0"
                      y="0"
                      width="88"
                      height="20"
                      rx="2"
                    ></rect>
                    <rect
                      fill-opacity="0.5"
                      fill="url(#linearGradient-_kc5qk8wd8-17)"
                      x="0"
                      y="0"
                      width="88"
                      height="20"
                      rx="2"
                    ></rect>
                  </g>
                  <g
                    transform="translate(0.000000, 24.000000)"
                    fill="url(#linearGradient-_kc5qk8wd8-18)"
                    fill-opacity="0.8"
                  >
                    <rect
                      x="0"
                      y="5.68434189e-14"
                      width="88"
                      height="205"
                      rx="2"
                    ></rect>
                  </g>
                </g>
                <g opacity="0.5" transform="translate(1145.684000, 75.000000)">
                  <rect
                    fill-opacity="0.05"
                    fill="#F8F8F8"
                    x="0"
                    y="0"
                    width="88"
                    height="20"
                    rx="2"
                  ></rect>
                  <rect
                    fill-opacity="0.5"
                    fill="url(#linearGradient-_kc5qk8wd8-17)"
                    x="0"
                    y="0"
                    width="88"
                    height="20"
                    rx="2"
                  ></rect>
                </g>
                <g
                  opacity="0.8"
                  transform="translate(1145.684000, 99.000000)"
                  fill="url(#linearGradient-_kc5qk8wd8-19)"
                  fill-opacity="0.8"
                >
                  <rect
                    x="0"
                    y="5.68434189e-14"
                    width="88"
                    height="248"
                    rx="2"
                  ></rect>
                </g>
                <g opacity="0.5" transform="translate(1241.684000, 0.000000)">
                  <rect
                    fill-opacity="0.05"
                    fill="#F8F8F8"
                    x="0"
                    y="2.84217094e-14"
                    width="87"
                    height="20"
                    rx="2"
                  ></rect>
                  <rect
                    fill-opacity="0.5"
                    fill="url(#linearGradient-_kc5qk8wd8-20)"
                    x="0"
                    y="2.84217094e-14"
                    width="87"
                    height="20"
                    rx="2"
                  ></rect>
                </g>
                <rect
                  fill-opacity="0.5"
                  fill="url(#linearGradient-_kc5qk8wd8-21)"
                  x="1241.684"
                  y="24"
                  width="87"
                  height="323"
                  rx="2"
                ></rect>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
}
function anime(arg0: {
  targets: string;
  translateX: any;
  translateY: any;
  rotate: any;
  easing: string;
  duration: number;
  loop: boolean;
}) {
  throw new Error("Function not implemented.");
}
