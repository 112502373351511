import soure from "@/utils/soucre";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import HeaderItem from "./components/headerItem";
import "swiper/css";
import { useEffect, useState } from "react";
import NewItem from "./components/newItem";
import { getNewsList, getHotList, getNewBanner } from "@/api/index";
import { useLang } from "@/hooks/index";
import Page from "@/components/page";
import { Link } from "react-router-dom";

export default function News() {
  const tt = useTranslation().t;
  const [siwperEl, setSiwperEl] = useState<any>();
  const [banner, setBanner] = useState<any>([]);
  const [hotList, setHotList] = useState<any>([]);
  const [list, setList] = useState<any>([]);
  const lang = useLang();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [total, setTotal] = useState(0);

  const getHotListFun = () => {
    getHotList({ size: 5 }).then((res: any) => {
      setHotList(res);
    });
  };

  const getNewBannerFun = () => {
    getNewBanner({ size: 5 }).then((res: any) => {
      setBanner(res);
    });
  };

  const getNewsListFun = () => {
    getNewsList({ pageNo, pageSize }).then((res: any) => {
      setList(res.list || []);
      setTotal(res.total || 0);
    });
  };

  const newChange = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
    setPageSize(pageSize);
  };

  useEffect(() => {
    getNewBannerFun();
    getHotListFun();
  }, []);

  useEffect(() => {
    getNewsListFun();
  }, [pageNo, pageSize]);

  return (
    <div
      className="w-full  bg-no-repeat pb-[100px]"
      style={{
        backgroundImage: `url(${soure.newBg})`,
        backgroundSize: "100%",
        backgroundColor: "#050506",
      }}
    >
      <div className="xl:w-[85vw] w-[90vw] pt-[196px] mx-auto">
        {/* 标题 */}
        <div className="mb-[36px]">
          <div className="h48 text-center text-[#fff]">{tt("news.text1")}</div>
          <div className="text-center">
            <div className="p20 textColor_white mt-[16px]">
              {tt("news.text2")}
            </div>
          </div>
        </div>
        {/* 内容 */}
        <div className="black_blur">
          <div className="black_opacity min-h-[100vh]">
            {/* l轮播 */}
            <div className="pt-[72px]">
              <Swiper
                slidesPerView={2}
                spaceBetween={30}
                slidesOffsetBefore={100}
                onSwiper={(el: any) => {
                  setSiwperEl(el);
                }}
              >
                {banner.map((item: any, index: number) => {
                  return (
                    <SwiperSlide key={item.id}>
                      <Link to={"/newDetail/" + item.id}>
                        <HeaderItem item={item}></HeaderItem>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="text-[rgba(255,255,255,0.5)] my-[60px] text-center">
              <span
                className="iconfont icon-a-RoundAltArrowRight text-[34px]"
                onClick={() => {
                  siwperEl && siwperEl.slidePrev();
                }}
              ></span>
              <span
                className="iconfont icon-a-RoundAltArrowRight-copy text-[34px] ml-[24px]"
                onClick={() => {
                  siwperEl && siwperEl.slideNext();
                }}
              ></span>
            </div>
            <div className="w-[90%] mx-auto">
              <img src={soure.splitLine} className="w-[100%]" alt="" />
            </div>
            <div className="xl:flex xl:mx-[70px] mx-[10px] my-[54px]">
              {/* 新闻列表 */}
              <div className="xl:w-[75%] mr-[30px]">
                <div className="text-[rgba(255,255,255,0.5)] h20">
                  {tt("news.text3")}
                </div>
                {list.map((item: any) => {
                  return (
                    <Link to={"/newDetail/" + item.id} key={item.id}>
                      <NewItem item={item}></NewItem>;
                    </Link>
                  );
                })}
                <div>
                  <Page onChange={newChange} total={total}></Page>
                </div>
              </div>
              {/* 热点新闻 */}
              <div className="xl:w-[25%] rounded-[8px] border-[1px] border-[rgba(255,255,255,0.1)] p-[20px]">
                <div className="text-[rgba(255,255,255,0.5)] h20">
                  {tt("news.text4")}
                </div>
                <div>
                  {hotList.map((item: any) => {
                    return (
                      <Link to={"/newDetail/" + item.id} key={item.id}>
                        <div className="text-[#fff] mt-[18px]" key={item.id}>
                          <div className="h20">
                            {lang === "zh" ? item.title : item.enTitle}
                          </div>
                          <p className="line-clamp-2 p16 mt-[10px]">
                            {lang === "zh" ? item.desc : item.enDesc}
                          </p>
                          <p className="p16 text-[rgba(255,255,255,0.5)] mt-[10px]">
                            AIXG . {item.createDate}
                          </p>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
