const data = {
  text1: "AiXG KOL Privilege Program",
  text2:
    "As a KOL partner of AiXG, you can earn lucrative commissions by sharing your exclusive invitation code or invitation link and inviting your friends or community members to sign up and trade (both spot and contract). Joining AiXG's KOL program will not only help you increase your income, but also help you build a higher personal influence in the industry. Below are the exclusive privileges and rebate mechanisms for KOL partners.",
  listTitle1: "Up to 80% rebate rate",
  listContent1:
    "AiXG offers industry-leading rebate rates, with KOL partners enjoying a maximum rebate rate of 60%. Allowing you to continuously earn profits through each user transaction, achieving double commission for 'lying down'.",
  listTitle2: "Up to 80% rebate rate",
  listContent2:
    "AiXG offers industry-leading rebate rates, with KOL partners enjoying a maximum rebate rate of 60%. Allowing you to continuously earn profits through each user transaction, achieving double commission for 'lying down'.",
  listTitle3: "Up to 80% rebate rate",
  listContent3:
    "AiXG offers industry-leading rebate rates, with KOL partners enjoying a maximum rebate rate of 60%. Allowing you to continuously earn profits through each user transaction, achieving double commission for 'lying down'.",
  listTitle4: "Up to 80% rebate rate",
  listContent4:
    "AiXG offers industry-leading rebate rates, with KOL partners enjoying a maximum rebate rate of 60%. Allowing you to continuously earn profits through each user transaction, achieving double commission for 'lying down'.",
  listTitle5: "Up to 80% rebate rate",
  listContent5:
    "AiXG offers industry-leading rebate rates, with KOL partners enjoying a maximum rebate rate of 60%. Allowing you to continuously earn profits through each user transaction, achieving double commission for 'lying down'.",
  listTitle6: "Up to 80% rebate rate",
  listContent6:
    "AiXG offers industry-leading rebate rates, with KOL partners enjoying a maximum rebate rate of 60%. Allowing you to continuously earn profits through each user transaction, achieving double commission for 'lying down'.",
  title: "How do I earn commissions?",
  question1: "1. Submission of applications",
  answer1:
    "- Fill out the application form and the AiXG team will evaluate your application and approve it to join the KOL program after meeting the criteria.",
  question2: "2. Posting of invitation links",
  answer2:
    "- Create, manage and track the effectiveness of your invitation links within your AiXG account, making it easy to see the progress of your promotions in real time.",
  question3: "3. Sit back and wait for the proceeds to arrive",
  answer3:
    "- After a user registers and trades using your invitation link, you will receive commission earnings on each of their trades.",
  btn: "Application Requirements",
  bulletTitle: "Join AIXG Global KOL Partners to Earn Higher Bonuses",
  baseInfo: "Basic information",
  form: {
    name: "Name",
    namePlaceholder: "Please enter your name",
    email: "E-mail",
    emialPlaceholder: "Please enter your email",
    media: "Social media",
    mediaOption1: "Twitter",
    mediaOption2: "YouTube",
    mediaOption3: "Facebook",
    mediaOption4: "TikTok",
    mediaOption5: "Instagram",
    mediaOption6: "KakaoTalk",
    UID: "UID",
    UIDPlaceholder: "Please enter your UID",
    fansNumber: "Fans number",
    fansNumberOption1: "1-1,000,000",
    fansNumberOption2: "1,000,000-5,000,000",
    fansNumberOption3: "5,000,000-10,000,000",
    fansNumberOption4: "More than 10,000,000",
    type: "type",
    typeOption1: "Traffic Website",
    typeOption2: "Video Blogger",
    typeOption3: "Social Media",
    typeOption4: "Analyst",
    typeOption5: "Agent",
    typeOption6: "Secondary Agent",
  },
  proof: "Proof of influence",
  proofDesc: "Fill in at least one item",
};
export default data;
