import { Result } from "@/types";
import { message as Message } from "antd";
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";

console.log(process.env)
// 创建 axios 实例
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});

// 请求拦截
axiosInstance.interceptors.request.use(
  (config) => {
    // 在请求被发送之前做些什么
    return config;
  },
  (error) => {
    // 请求错误时做些什么
    return Promise.reject(error);
  }
);

// 响应拦截
axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    if (!res.data) throw new Error("请求失败");

    const { code, data, message } = res.data;
    // 业务请求成功
    const hasSuccess = code === 200;
    if (hasSuccess) {
      return data;
    }
    Message.error(message);
    // 业务请求错误
    throw new Error(message || "请求失败");
  },
  (error: AxiosError) => {
    const { response, message } = error || {};

    const errMsg = response?.data?.message || message || "请求失败";
    Message.error(errMsg);

    const status = response?.status;
    return Promise.reject(error);
  }
);

class APIClient {
  get<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.request({ ...config, method: "GET" });
  }

  post<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.request({ ...config, method: "POST" });
  }

  put<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.request({ ...config, method: "PUT" });
  }

  delete<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.request({ ...config, method: "DELETE" });
  }

  request<T = any>(config: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .request<any, AxiosResponse<Result<T>>>(config)
        .then((res: AxiosResponse<Result<T>>) => {
          resolve(res as unknown as Promise<T>);
        })
        .catch((e: Error | AxiosError) => {
          reject(e);
        });
    });
  }
}
export default new APIClient();
