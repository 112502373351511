import soure from "@/utils/soucre";
import "swiper/css";
import { useEffect, useState } from "react";
import "./index.scss";
import PageBg from "@/components/pageBg";
import { getHireDetail } from "@/api/index";
import { useParams } from "react-router-dom";
import { useLang } from "@/hooks/index";

export default function Hire() {
  const { id } = useParams();
  const [detail, setDetail] = useState<any>({
    title: "",
    enTitle: "",
    desc: "",
    enDesc: "",
    conten: "",
    enContent: "",
  });
  const lang = useLang();

  useEffect(() => {
    if (id) {
      getDetailFun();
    }
  }, [id]);

  const getDetailFun = () => {
    getHireDetail({
      id,
    }).then((res: any) => {
      setDetail(res);
    });
  };

  return (
    <div
      className="w-full  bg-no-repeat pb-[100px]"
      style={{
        backgroundImage: `url(${soure.newBg})`,
        backgroundSize: "100%",
        backgroundColor: "#050506",
      }}
    >
      <div className="xl:w-[85vw] w-[90vw] pt-[196px] mx-auto">
        {/* 标题 */}
        <div className="mb-[36px]">
          <div className="h48 text-center text-[#fff]">
            {lang === "zh" ? detail.title : detail.enTitle}
          </div>
          <div className="text-center">
            <div className="p20 textColor_white mt-[16px]">
              {lang === "zh" ? detail.desc : detail.enDesc}
            </div>
          </div>
        </div>
        {/* 内容 */}
        <PageBg>
          <div className="cus_pages"
            dangerouslySetInnerHTML={{
              __html: lang === "zh" ? detail.content : detail.enContent,
            }}
          ></div>
        </PageBg>
      </div>
    </div>
  );
}
