import source, { list1, list2 } from "@/utils/soucre";
import "./index.css";
import { useTranslation } from "react-i18next";
import TopMarket from "./topMarket";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { speed } from "@/utils/const";
import Page from "@/components/page";
import { ReactComponent as Binance } from "@/assets/home/logoicon/logo10.svg";
import { ReactComponent as Ethereum } from "@/assets/home/logoicon/logo6.svg";
import { ReactComponent as Monero } from "@/assets/home/logoicon/logo11.svg";
import { ReactComponent as Solana } from "@/assets/home/logoicon/logo5.svg";
import { ReactComponent as TetherUSD } from "@/assets/home/logoicon/logo7.svg";
import { ReactComponent as Cosmos } from "@/assets/home/logoicon/logo1.svg";
import { ReactComponent as Bitcoin } from "@/assets/home/logoicon/logo0.svg";
import { ReactComponent as Line1 } from "@/assets/home/threeLinne/01.svg";
import { ReactComponent as Line2 } from "@/assets/home/threeLinne/02.svg";
import { ReactComponent as Line3 } from "@/assets/home/threeLinne/03.svg";
import { ReactComponent as Line4 } from "@/assets/home/threeLinne/04.svg";
export default function BlockThree() {
  const tt = useTranslation().t;

  return (
    <div
      className="bg-cover"
      style={{
        backgroundImage: `url(${source.home.homeB03})`,
        backgroundSize: "100% 100%",
      }}
    >
      <div className="wow animate__fadeIn" data-wow-duration="5s">
        {/* market */}
        <div className="h48 text-[#fff] text-center  weight-b pt-[100px] xl:mb-[16px] font-pls_b">
          {tt("home.three.text1")}
        </div>
        {/* Coding Potential with Enigma Code-ai */}
        <div className="text-center">
          <div className="p20 textColor_white font-pls_m">{tt("home.three.text2")}</div>
        </div>
      </div>
      <div
        className="xl:mt-[50px] mt-[30px] xl:flex justify-center max-w-[1360px] mx-auto wow animate__fadeInUp"
        data-wow-duration="1s"
      >
        {/* 左边表格 */}
        <div className="xl:w-[960px] w-[90vw] mx-auto three_bg_white">
          <div className="">
            <div className="flex justify-start h-[42px] items-center ml-[16px]">
              <div className="w-[11px] h-[11px] rounded-[50%] bg-[#FE3838] mr-[3px]"></div>
              <div className="w-[11px] h-[11px] rounded-[50%] bg-[#FAEF4A] mr-[3px]"></div>
              <div className="w-[11px] h-[11px] rounded-[50%] bg-[#77F38A] mr-[3px]"></div>
            </div>
          </div>
          <div
            className="rounded-[12px] overflow-hidden m-[8px] border-[1px] border-[rgba(255,255,255,0.2)] wow animate_opacity opacity-0"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <table className="three_table">
              <thead className="bg-[#000]">
                <tr className="xl:text-[16px] text-[12px]">
                  <th className="w-[8%] font-pls_sb"></th>
                  <th className="font-pls_sb">{tt("home.three.th01")}</th>
                  <th className="w-[15%] font-pls_sb">{tt("home.three.th02")}</th>
                  <th className="w-[15%] font-pls_sb">{tt("home.three.th03")}</th>
                  <th className="w-[10%] font-pls_sb">{tt("home.three.th04")}</th>
                  <th className="w-[20%] font-pls_sb">{tt("home.three.th05")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="w-[100px] text-center">1</td>
                  <td>
                    <div className="flex items-center">
                      <div className="w-[44px] h-[44px] rounded-[50%] bg-[red]overflow-hidden mr-[10px]">
                        <Binance></Binance>
                      </div>
                      <div>
                        <div className="xl:text-[16px] text-[12px] font-medium text-[#fff] text-left font-pls_m">
                          Binance
                        </div>
                        <div className="xl:text-[14px] text-[10px] text-[#CFD3DC]">
                          BNB | 4.35% APY
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="p16 font-pls_b">$260.31</td>
                  <td>
                    <Line1></Line1>
                  </td>
                  <td
                    className={[
                      "p16",
                      "font-pls_m",
                      "xl:text-[16px] text-[10px]",
                    ].join(" ")}
                    style={{ color: true ? "#00CD52" : "#DD3131" }}
                  >
                    +8.11%
                  </td>
                  <td className="text-ce">
                    <button className="xl:px-[55px] xl:py-[8px] px-[10px] py-[4px] rounded-[8px] bg-cover three_btn p16">
                      Buy
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="w-[100px] text-center">2</td>
                  <td>
                    <div className="flex items-center">
                      <div className="w-[44px] h-[44px] rounded-[50%] bg-[red]overflow-hidden mr-[10px]">
                        <Ethereum></Ethereum>
                      </div>
                      <div>
                        <div className="xl:text-[16px] text-[12px] font-medium text-[#fff] text-left font-pls_m">
                          Ethereum
                        </div>
                        <div className="xl:text-[14px] text-[10px] text-[#CFD3DC]">
                          ETH | 3.21% APY
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="p16 font-pls_m font-pls_m">$150.68</td>
                  <td>
                    <Line2></Line2>
                  </td>
                  <td
                    className={[
                      "p16",
                      "font-pls_m",
                      "xl:text-[16px] text-[10px]",
                    ].join(" ")}
                    style={{ color: false ? "#00CD52" : "#DD3131" }}
                  >
                    -4.71%
                  </td>
                  <td className="text-ce">
                    <button className="xl:px-[55px] xl:py-[8px] px-[10px] py-[4px] rounded-[8px] bg-cover three_btn p16">
                      Buy
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="w-[100px] text-center">3</td>
                  <td>
                    <div className="flex items-center">
                      <div className="w-[44px] h-[44px] rounded-[50%] bg-[red]overflow-hidden mr-[10px]">
                        <Monero></Monero>
                      </div>
                      <div>
                        <div className="xl:text-[16px] text-[12px] font-medium text-[#fff] text-left font-pls_m">
                          Monero
                        </div>
                        <div className="xl:text-[14px] text-[10px] text-[#CFD3DC]">
                          XMR | 1.56% APY
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="p16 font-pls_m ">$310.20</td>
                  <td>
                    <Line3></Line3>
                  </td>
                  <td
                    className={[
                      "p16",
                      "font-pls_m",
                      "xl:text-[16px] text-[10px]",
                    ].join(" ")}
                    style={{ color: false ? "#00CD52" : "#DD3131" }}
                  >
                    -2.50%
                  </td>
                  <td className="text-ce">
                    <button className="xl:px-[55px] xl:py-[8px] px-[10px] py-[4px] rounded-[8px] bg-cover three_btn p16">
                      Buy
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="w-[100px] text-center">4</td>
                  <td>
                    <div className="flex items-center">
                      <div className="w-[44px] h-[44px] rounded-[50%] bg-[red]overflow-hidden mr-[10px]">
                        <Solana></Solana>
                      </div>
                      <div>
                        <div className="xl:text-[16px] text-[12px] font-medium text-[#fff] text-left">
                          Solana
                        </div>
                        <div className="xl:text-[14px] text-[10px] text-[#CFD3DC]">
                          SOL | 3.65% APY
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="p16 font-pls_m ">$100.15</td>
                  <td>
                    <Line4></Line4>
                  </td>
                  <td
                    className={[
                      "p16",
                      "font-pls_m",
                      "xl:text-[16px] text-[10px]",
                    ].join(" ")}
                    style={{ color: true ? "#00CD52" : "#DD3131" }}
                  >
                    +1.80%
                  </td>
                  <td className="text-ce">
                    <button className="xl:px-[55px] xl:py-[8px] px-[10px] py-[4px] rounded-[8px] bg-cover three_btn p16">
                      Buy
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              className="px-[30px] py-[15px] black-white-griad"
              style={{
                backgroundImage: `linear-gradient(to right, #000 0%, #000 50%, #000000 50%, #000 100%)`,
              }}
            >
              <Page onChange={() => {}} total={1}></Page>
            </div>
          </div>
        </div>
        {/* 右边列表 */}
        <div className="xl:w-[400px] w-[90vw] mx-auto  xl:ml-[16px] px-[24px] py-[16px] three_bg_white mt-[20px] xl:mt-[0px]">
          <div className="flex justify-between">
            <div className="p18 text-[#CFD3DC] weight-b">
              {tt("home.three.top")}
            </div>
            <div className="h18 text-[rgba(255,255,255,0.5)] font-pls_m">
              {tt("home.three.seeAll")}
            </div>
          </div>
          <div
            className="wow animate_opacity opacity-0"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <TopMarket
              icon={<Bitcoin></Bitcoin>}
              title="Bitcoin"
              type="BTC"
              price="S$4.20"
              number="-1.80%"
              isred={true}
            ></TopMarket>
            <TopMarket
              icon={<Ethereum></Ethereum>}
              title="Ethereum"
              type="ETH"
              price="S$8.50"
              number="+5.80%"
              isDown={false}
            ></TopMarket>
            <TopMarket
              icon={<TetherUSD></TetherUSD>}
              title="Tether USD"
              type="USDT"
              price="S$3.10"
              number="-9.99%"
              isDown={false}
              isred={true}
            ></TopMarket>
            <TopMarket
              icon={<Binance></Binance>}
              title="Binance"
              type="BNB"
              price="S$10.90"
              number="+5.20%"
              isDown={false}
            ></TopMarket>
            <TopMarket
              icon={<Monero></Monero>}
              title="Monero"
              type="XMR"
              price="S$1.10"
              number="-2.80%"
              isDown={false}
              isred={true}
            ></TopMarket>
            <TopMarket
              icon={<Cosmos></Cosmos>}
              title="Cosmos"
              type="ATOM"
              price="S$1.10"
              number="+3.10%%"
              isDown={false}
              isred={false}
            ></TopMarket>
          </div>
        </div>
      </div>
      {/* 下方滚动 */}
      <div className="relative">
        <div className="black_trans_y absolute top-0 left-0 w-full h-full z-10"></div>
        <div className="mt-[50px]">
          <Swiper
            spaceBetween={50}
            slidesPerView={4}
            speed={speed}
            loop={true}
            autoplay={{
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            {list1.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <img src={item} alt="" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="mt-[50px]">
          <Swiper
            spaceBetween={50}
            slidesPerView={4}
            speed={speed}
            loop={true}
            autoplay={{
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              reverseDirection: true,
            }}
            modules={[Autoplay]}
          >
            {list2.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <img src={item} alt="" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
