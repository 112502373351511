import soucre from "@/utils/soucre";
export default function PageBg(props: any) {
  const { paddingClass } = props;
  return (
    <div className="black_blur">
      <div className="black_opacity min-h-[30vh] text-[#fff] text-center">
        <div
          className={paddingClass ? paddingClass : "2xl:px-[170px] px-[20px] overflow-auto"}
        >
          {props.children}
        </div>
        <div className="mx-[30px]">
          <img src={soucre.pageEndLine} className="w-full" alt="" />
        </div>
      </div>
    </div>
  );
}
