import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getCusPageList } from "@/api";
import { Link } from "react-router-dom";
import { useLang } from "@/hooks";
import Page from "@/components/page";
export default function Question() {
  const tt = useTranslation().t;
  const [pageNo, setPageNo] = useState(1);
  const [list, setList] = useState([]);
  const [pageSize, setPageSize] = useState(4);
  const [total, setTotal] = useState(0);
  const lang = useLang();

  const newChange = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
    setPageSize(pageSize);
  };

  useEffect(() => {
    getCusPageListFun();
  }, [pageNo, pageSize]);

  const getCusPageListFun = () => {
    getCusPageList({ pageNo, pageSize, type: "question" }).then((res: any) => {
      setList(res.list || []);
      setTotal(res.total || 0);
    });
  };

  return (
    <div className="w-[90vw] mx-auto mt-[40px]">
      {/* 内容 */}
      <div className="flex flex-col bullet_box_bg text-[#fff] px-[60px]">
        <div className="h32 text-center mt-[60px] mb-[30px]">
          {tt("helpCenter.text4")}
        </div>
        <div className="mb-[72px]">
          {list.map((item: any) => {
            return (
              <Link to={"/cusPage/" + item.id} key={item.id}>
                <div className="w-full bg-[rgba(0,0,0,06)] flex justify-between h-[76px] items-center mt-[10px] rounded-[16px] px-[56px]">
                  <div className="p20 font-pls_m">
                    {lang === "zh" ? item.title : item.enTitle}
                  </div>
                  <div className="text-[30px] icon-a-ArrowRightUp iconfont"></div>
                </div>
              </Link>
            );
          })}
        </div>
        <div className="mt-[30px] mb-[40px]">
          <Page onChange={newChange} total={total}></Page>
        </div>
      </div>
    </div>
  );
}
