import soucre from "@/utils/soucre";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { sreenXL } from "@/utils/const";
import { useIsMoile } from "@/hooks";
import { NavLink } from "react-router-dom";

export default function NavItem(props: any) {
  const {
    list,
    name,
    icon = "icon-icons-4",
    index,
    selectIndex,
    click,
  } = props;
  const tt = useTranslation().t;
  const [show, setShow] = useState(false);
  const isMobile = useIsMoile(sreenXL);

  useEffect(() => {
    isMobile && setShow(selectIndex === index);
  }, [selectIndex]);
  return (
    <div
      onMouseEnter={() => {
        !isMobile && setShow(true);
      }}
      onMouseLeave={() => {
        !isMobile && setShow(false);
      }}
      onClick={() => {
        if (isMobile) {
          click(index === selectIndex ? null : index);
        }
      }}
    >
      <div
        className={[
          !isMobile ? "one_level relative m-[5px] py-[10px] px-[10px] " : "",
        ].join(" ")}
      >
        <div
          className={[
            isMobile
              ? "flex w-[220px] h-[40px] justify-between items-center"
              : "",
          ].join(" ")}
        >
          <div>
            <span
              className={["iconfont", icon, "text-[18px] mr-[5px]"].join(" ")}
            ></span>
            <span>{tt(name)}</span>
          </div>
          {isMobile && list.length > 0 && (
            <CSSTransition
              in={show}
              appear={show}
              timeout={1000}
              classNames="arrow"
            >
              <div className="iconfont icon-xiangshangjiantou text-[12px] rotate-180"></div>
            </CSSTransition>
          )}
        </div>
        <CSSTransition in={show} timeout={500} unmountOnExit classNames="fade">
          <div className="xl:absolute xl:top-[46px] overflow-hidden">
            {list.length > 0 && (
              <div
                className="xl:px-[36px] px-[24px] xl:py-[16px] py-[10px] rounded-[8px] mt-[10px] xl:border-[1px] border-[rgba(255,255,255,0.3)]"
                style={{
                  backgroundImage: !isMobile
                    ? `url(${soucre.header.navbg})`
                    : "",
                  backgroundSize: "100% 100%",
                  backgroundColor: isMobile ? "rgba(255,255,255,0.2)" : "",
                }}
              >
                <div
                  className="h-[3px] w-[200px] absolute top-0 left-0 z-10 bg-cover"
                  style={{ backgroundImage: `url(${soucre.home.line01})` }}
                ></div>
                {list.map((item: any) => {
                  return (
                    <NavLink key={item.name} to={item.url}>
                      <p className="p14 my-[10px] min-w-[150px] hover:font-bold">
                        {tt(item.name)}
                      </p>
                    </NavLink>
                  );
                })}
              </div>
            )}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
}
