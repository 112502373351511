import { useState, useEffect, useCallback } from "react";
import i18n from "i18next";
export function useIsMoile(width = 1080) {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth > width ? false : true
  );

  const onResize = useCallback(() => {
    setIsMobile(window.innerWidth > width ? false : true);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return isMobile;
}

export function useLang() {
  const [lang, setLang] = useState("en");

  const onChangeLang = useCallback(() => {
    setLang(i18n.language);
  }, []);
  useEffect(() => {
    // 监听语言变化事件
    i18n.on("languageChanged", onChangeLang);

    // 清理函数，移除监听器
    return () => {
      i18n.off("languageChanged", onChangeLang);
    };
  }, []);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  return lang;
}
